import React, { useEffect, useState, useCallback } from 'react';
import { getUsersListFromState, setUsersList } from '@/store/usersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import { useUsersApi } from '@/api-client/users-api';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    MenuItem,
    TableSortLabel,
    CircularProgress,
} from '@mui/material';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import { BusinessTeam } from '@/types';
import { getBusinessTeamsFullListFromState } from '@/store/businessTeamsSlice';
import CustomButton from '@/components/custom-button';
import CustomSelect from '@/components/custom-select';
import CustomTextField from '@/components/custom-text-field';
import ClearIcon from '@mui/icons-material/Clear';

const Users: React.FC = () => {
    const { getUsersList } = useUsersApi();
    const dispatch: AppDispatch = useDispatch();
    const usersList = useSelector((state: RootState) => getUsersListFromState(state));
    const businessTeamsList = useSelector(getBusinessTeamsFullListFromState);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [businessTeam, setBusinessTeam] = useState<string>('');
    const [sortBy, setSortBy] = useState<string>('id');
    const [sortDir, setSortDir] = useState<'asc' | 'desc'>('desc');
    const [loading, setLoading] = useState(true);

    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchTerm(event.target.value);
    };

    const handleBusinessTeamChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        setBusinessTeam(event.target.value as string);
    };

    const handleSortChange = (property: string): void => {
        const isAsc = sortBy === property && sortDir === 'asc';
        setSortDir(isAsc ? 'desc' : 'asc');
        setSortBy(property);
    };

    const fetchUsersList = async (
        keyword: string,
        businessTeam: string,
        currentPage: number,
        currentRowsPerPage: number,
        currentSortBy: string,
        currentSortDir: string,
    ): Promise<void> => {
        setLoading(true);
        try {
            const list = await getUsersList({
                keyword,
                businessTeam,
                page: currentPage,
                perPage: currentRowsPerPage,
                sortBy: currentSortBy,
                sortDir: currentSortDir,
            });
            dispatch(setUsersList(list));
        } catch (error) {
            console.error('Error fetching user list:', error);
        } finally {
            setLoading(false);
        }
    };

    const debouncedFetchUsersList = useCallback(
        debounce(
            (
                keyword: string,
                businessTeam: string,
                currentPage: number,
                currentRowsPerPage: number,
                currentSortBy: string,
                currentSortDir: string,
            ) => {
                fetchUsersList(keyword, businessTeam, currentPage, currentRowsPerPage, currentSortBy, currentSortDir);
            },
            500,
        ),
        [],
    );

    useEffect(() => {
        debouncedFetchUsersList(searchTerm, businessTeam, page + 1, rowsPerPage, sortBy, sortDir);
    }, [page, rowsPerPage, sortBy, sortDir]);

    return (
        <div style={{ padding: '25px' }}>
            <Paper style={{ padding: '25px' }}>
                <Link to={'/user/create'}>
                    <CustomButton label="+ Create a new user" />
                </Link>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        margin: '15px 0',
                        gap: '15px',
                    }}
                >
                    <CustomTextField
                        label="Search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        fullWidth
                        margin="normal"
                    />
                    <CustomSelect
                        label="Business Team"
                        value={businessTeam || 'none'}
                        onChange={(e) => handleBusinessTeamChange(e)}
                        fullWidth
                    >
                        <MenuItem value="none" disabled>
                            None
                        </MenuItem>
                        {businessTeamsList.items
                            .filter((team: BusinessTeam) => team.isActive)
                            .map((team: BusinessTeam) => (
                                <MenuItem key={team.id} value={team.id}>
                                    {team.name}
                                </MenuItem>
                            ))}
                    </CustomSelect>
                    <ClearIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setSearchTerm('');
                            setBusinessTeam('');
                            debouncedFetchUsersList('', '', 1, rowsPerPage, sortBy, sortDir);
                        }}
                    />
                    <CustomButton
                        label="Submit"
                        onClick={() =>
                            debouncedFetchUsersList(searchTerm, businessTeam, 1, rowsPerPage, sortBy, sortDir)
                        }
                    />
                </div>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '25px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {[
                                            'id',
                                            'email',
                                            'firstName',
                                            'lastName',
                                            'role',
                                            'locale',
                                            'timeZone',
                                            'businessTeam',
                                        ].map((column) => (
                                            <TableCell style={{ border: '1px solid #030F2B' }} key={column}>
                                                {['id', 'email', 'firstName', 'lastName'].includes(column) ? (
                                                    <TableSortLabel
                                                        active={sortBy === column}
                                                        direction={sortBy === column ? sortDir : 'asc'}
                                                        onClick={() => handleSortChange(column)}
                                                    >
                                                        {column.charAt(0).toUpperCase() + column.slice(1)}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.charAt(0).toUpperCase() + column.slice(1)
                                                )}
                                            </TableCell>
                                        ))}
                                        <TableCell style={{ border: '1px solid #030F2B' }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {usersList.items.map((user) => (
                                        <TableRow key={user.id}>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>{user.id}</TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>{user.email}</TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                {user.firstName}
                                            </TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                {user.lastName}
                                            </TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>{user.role}</TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>{user.locale}</TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                {user.timeZone}
                                            </TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                {user.businessTeams.map((team: BusinessTeam) => (
                                                    <div key={team.id + team.name}>{team.name}</div>
                                                ))}
                                            </TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <Link to={`/user/${user.id}`}>
                                                        <CustomButton label="Edit" />
                                                    </Link>
                                                    <Link to={`/user/create`} state={user}>
                                                        <CustomButton label="Clone" />
                                                    </Link>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={usersList.totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </Paper>
        </div>
    );
};

export default Users;
