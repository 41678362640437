import { useApiClient } from '@/api-client/okta-api-client-controller';

interface PaginatedResponse<T> {
    items: T[];
    currentPageNumber: number;
    numItemsPerPage: number;
    totalCount: number;
}

interface BusinessUser {
    id: number;
    firstName: string;
    lastName: string;
    locale: string;
    timeZone: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useBusinessUserApi = () => {
    const { apiClient } = useApiClient();

    const getBusinessUserUsersList = async (): Promise<PaginatedResponse<BusinessUser>> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<PaginatedResponse<BusinessUser>>(
            `/api/v1/business-user/users`,
            { method: 'GET' },
            'getBusinessUserUsersList',
        );
    };

    return {
        getBusinessUserUsersList,
    };
};
