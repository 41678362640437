import React, { useState, useEffect } from 'react';
import { Grid, FormControl, FormControlLabel, MenuItem, RadioGroup, Radio, Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getConfigurationsCurrentConfiguration } from '@/store/configurationsSlice';
import { useConfigurationsApi } from '@/api-client/configurations-api';
import { useSnackbar } from 'notistack';
import CustomSelect from '@/components/custom-select';

interface Props {
    isDraft: boolean;
    configurationId: string | null;
}

const scheduleOptions = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    BIWEEKLY: 'bi-weekly',
    MONTHLY: 'monthly',
};

const minutesOptions = Array.from({ length: 4 }, (_, index) => index * 15); // 0, 15, 30, 45

const SendingDataToBQ: React.FC<Props> = ({ isDraft, configurationId }) => {
    const configuration = useSelector(getConfigurationsCurrentConfiguration);
    const { putDraftConfigurationSendingOptionsUpdate, putConfigurationSendingOptionsUpdate } = useConfigurationsApi();
    const { enqueueSnackbar } = useSnackbar();

    const [scheduleType, setScheduleType] = useState(scheduleOptions.DAILY);
    const [scheduleTime, setScheduleTime] = useState('');
    const [dayWeek, setDayWeek] = useState('');
    const [dayMonth, setDayMonth] = useState<number | null>(null);
    const [configurationType, setConfigurationType] = useState(configuration?.sendingDataToBQ?.type || 'automatic');
    const [nextUploadMessage, setNextUploadMessage] = useState<string | null>(null);

    useEffect(() => {
        setConfigurationType(configuration?.sendingDataToBQ?.type || 'automatic');
        if (configuration?.sendingDataToBQ?.type === 'schedule') {
            setScheduleType(configuration.sendingDataToBQ.scheduleResource.periodicity);
            setScheduleTime(
                `${configuration.sendingDataToBQ.scheduleResource.hour}:${String(configuration.sendingDataToBQ.scheduleResource.minute).padStart(2, '0')}`,
            );
            setDayWeek(configuration.sendingDataToBQ.scheduleResource.dayWeek || '');
            setDayMonth(configuration.sendingDataToBQ.scheduleResource.dayMonth || null);
        }
    }, [configuration.sendingDataToBQ]);

    const handleScheduleTypeChange = (event): void => {
        setScheduleType(event.target.value);
    };

    const handleScheduleTimeChange = (event): void => {
        setScheduleTime(event.target.value);
    };

    const handleDayWeekChange = (event): void => {
        setDayWeek(event.target.value);
    };

    const handleDayMonthChange = (event): void => {
        setDayMonth(Number(event.target.value));
    };

    const handleSave = async (): Promise<void> => {
        const [hour, minute] = scheduleTime.split(':').map(Number);

        const data = {
            type: configurationType,
            schedule:
                configurationType === 'schedule'
                    ? {
                          periodicity: scheduleType,
                          hour,
                          minute,
                          dayWeek:
                              scheduleType === scheduleOptions.WEEKLY || scheduleType === scheduleOptions.BIWEEKLY
                                  ? dayWeek
                                  : null,
                          dayMonth: scheduleType === scheduleOptions.MONTHLY ? dayMonth : null,
                      }
                    : null,
        };

        try {
            if (isDraft) {
                await putDraftConfigurationSendingOptionsUpdate(Number(configurationId), data);
            } else {
                await putConfigurationSendingOptionsUpdate(Number(configurationId), data);
            }
            enqueueSnackbar('Sending options updated successfully', { variant: 'success' });

            // Calculate the next upload time for schedule type
            let nextUploadMessage = '';
            if (configurationType === 'schedule') {
                const { utcTime, localTime } = calculateNextUploadTime(scheduleType, hour, minute, dayWeek, dayMonth);
                nextUploadMessage = `Next upload will be done ${utcTime} UTC (your local time: ${localTime})`;
            } else if (configurationType === 'manual') {
                nextUploadMessage = 'Uploads must be triggered manually.';
            } else if (configurationType === 'automatic') {
                nextUploadMessage = 'Uploads are performed automatically.';
            }
            setNextUploadMessage(nextUploadMessage);
        } catch (error) {
            enqueueSnackbar('Failed to update sending options', { variant: 'error' });
        }
    };

    const calculateNextUploadTime = (
        periodicity: string,
        hour: number,
        minute: number,
        dayWeek: string,
        dayMonth: number | null,
    ): { utcTime: string; localTime: string } => {
        const now = new Date();
        const nextUpload = new Date(now);

        nextUpload.setUTCHours(hour);
        nextUpload.setUTCMinutes(minute);
        nextUpload.setUTCSeconds(0);

        if (periodicity === scheduleOptions.DAILY) {
            if (nextUpload <= now) nextUpload.setUTCDate(now.getUTCDate() + 1);
        } else if (periodicity === scheduleOptions.WEEKLY) {
            nextUpload.setUTCDate(
                now.getUTCDate() +
                    ((7 -
                        now.getUTCDay() +
                        ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(
                            dayWeek,
                        )) %
                        7 || 7),
            );
            if (nextUpload <= now) nextUpload.setUTCDate(nextUpload.getUTCDate() + 7);
        } else if (periodicity === scheduleOptions.BIWEEKLY) {
            nextUpload.setUTCDate(
                now.getUTCDate() +
                    ((7 -
                        now.getUTCDay() +
                        ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(
                            dayWeek,
                        )) %
                        7 || 14),
            );
            if (nextUpload <= now) nextUpload.setUTCDate(nextUpload.getUTCDate() + 14);
        } else if (periodicity === scheduleOptions.MONTHLY) {
            nextUpload.setUTCDate(dayMonth || 1);
            if (nextUpload <= now) nextUpload.setUTCMonth(now.getUTCMonth() + 1);
        }

        const utcTime = `${nextUpload.getUTCDate().toString().padStart(2, '0')}.${(nextUpload.getUTCMonth() + 1)
            .toString()
            .padStart(
                2,
                '0',
            )}.${nextUpload.getUTCFullYear()} at ${nextUpload.getUTCHours().toString().padStart(2, '0')}:${nextUpload
            .getUTCMinutes()
            .toString()
            .padStart(2, '0')}`;

        const localTime = `${nextUpload.getDate().toString().padStart(2, '0')}.${(nextUpload.getMonth() + 1)
            .toString()
            .padStart(
                2,
                '0',
            )}.${nextUpload.getFullYear()} at ${nextUpload.getHours().toString().padStart(2, '0')}:${nextUpload
            .getMinutes()
            .toString()
            .padStart(2, '0')}`;

        return { utcTime, localTime };
    };

    return (
        <>
            <Grid item xs={12}>
                <h4>Sending Data to BQ:</h4>
            </Grid>
            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <RadioGroup
                        value={configurationType}
                        row
                        onChange={(event) => {
                            setConfigurationType(event.target.value);
                        }}
                    >
                        <FormControlLabel value="automatic" control={<Radio />} label="Automatic" />
                        <FormControlLabel value="manual" control={<Radio />} label="Manual" />
                        <FormControlLabel value="schedule" control={<Radio />} label="Schedule" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            {configurationType === 'schedule' && (
                <>
                    <Grid item xs={6}>
                        <CustomSelect
                            label="Select an option"
                            value={scheduleType || ''}
                            onChange={handleScheduleTypeChange}
                        >
                            <MenuItem value={scheduleOptions.DAILY}>Daily</MenuItem>
                            <MenuItem value={scheduleOptions.WEEKLY}>Weekly</MenuItem>
                            <MenuItem value={scheduleOptions.BIWEEKLY}>Bi-weekly</MenuItem>
                            <MenuItem value={scheduleOptions.MONTHLY}>Monthly</MenuItem>
                        </CustomSelect>
                    </Grid>
                    {(scheduleType === scheduleOptions.WEEKLY || scheduleType === scheduleOptions.BIWEEKLY) && (
                        <Grid item xs={6}>
                            <CustomSelect label="Every week on:" value={dayWeek || ''} onChange={handleDayWeekChange}>
                                <MenuItem value="sunday">Sunday</MenuItem>
                                <MenuItem value="monday">Monday</MenuItem>
                                <MenuItem value="tuesday">Tuesday</MenuItem>
                                <MenuItem value="wednesday">Wednesday</MenuItem>
                                <MenuItem value="thursday">Thursday</MenuItem>
                                <MenuItem value="friday">Friday</MenuItem>
                                <MenuItem value="saturday">Saturday</MenuItem>
                            </CustomSelect>
                        </Grid>
                    )}
                    {scheduleType === scheduleOptions.MONTHLY && (
                        <Grid item xs={6}>
                            <CustomSelect
                                label="Each day in month:"
                                value={dayMonth || ''}
                                onChange={handleDayMonthChange}
                            >
                                {[...Array(28)].map((_, index) => (
                                    <MenuItem key={index + 1} value={index + 1}>
                                        {index + 1}
                                    </MenuItem>
                                ))}
                            </CustomSelect>
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <CustomSelect label="At (UTC):" value={scheduleTime || ''} onChange={handleScheduleTimeChange}>
                            {Array.from({ length: 24 }, (_, hour) =>
                                minutesOptions.map((minute) => (
                                    <MenuItem key={`${hour}:${minute}`} value={`${hour}:${minute}`}>
                                        {`${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`}
                                    </MenuItem>
                                )),
                            )}
                        </CustomSelect>
                    </Grid>
                </>
            )}
            <Grid item xs={12} marginTop={2}>
                <Button variant="contained" color="primary" onClick={handleSave}>
                    Apply
                </Button>
            </Grid>
            {nextUploadMessage && (
                <Grid item xs={12} marginTop={2}>
                    <Typography variant="body1">{nextUploadMessage}</Typography>
                </Grid>
            )}
        </>
    );
};

export default SendingDataToBQ;
