import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    CircularProgress,
    IconButton,
    Collapse,
} from '@mui/material';
import debounce from 'lodash/debounce';
import { useParams } from 'react-router-dom';
import { getConfigurationVersionListFromState, setConfigurationVersionList } from '@/store/configurationsSlice';
import { useActivityLogsApi } from '@/api-client/activity-logs-api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Logs: React.FC = () => {
    const { getActivityLogsConfigurationLogs } = useActivityLogsApi();
    const { id } = useParams<{ id: string }>();
    const dispatch: AppDispatch = useDispatch();
    const versionList = useSelector((state: RootState) => getConfigurationVersionListFromState(state));
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [expandedRow, setExpandedRow] = useState<number | null>(null); // State for expanded row

    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleExpandClick = (rowId: number): void => {
        setExpandedRow(expandedRow === rowId ? null : rowId);
    };

    const debouncedFetchConfigurationVersionList = useCallback(
        debounce((currentPage: number, currentRowsPerPage: number) => {
            fetchConfigurationVersion(currentPage, currentRowsPerPage);
        }, 500),
        [],
    );

    useEffect(() => {
        debouncedFetchConfigurationVersionList(page + 1, rowsPerPage);
    }, [page, rowsPerPage]);

    const fetchConfigurationVersion = async (currentPage: number, currentRowsPerPage: number): Promise<void> => {
        setLoading(true);
        try {
            const list = await getActivityLogsConfigurationLogs(id, currentPage, currentRowsPerPage);
            dispatch(setConfigurationVersionList(list));
        } catch (error) {
            console.error('Error fetching configuration version list:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Paper style={{ width: '100%' }}>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', padding: '25px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <TableContainer style={{ padding: '25px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {['createdAt', 'user', 'message', 'details'].map((column) => (
                                        <TableCell style={{ border: '1px solid #030F2B' }} key={column}>
                                            <h6>{column.charAt(0).toUpperCase() + column.slice(1)}</h6>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {versionList?.items.map((log) => (
                                    <React.Fragment key={log.id}>
                                        <TableRow>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                {log?.createdAt}
                                            </TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                {log?.userResource?.firstName} {log?.userResource?.lastName}
                                            </TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                {log?.message}
                                            </TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                <IconButton onClick={() => handleExpandClick(log.id)}>
                                                    {expandedRow === log.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ padding: 0 }} colSpan={5}>
                                                <Collapse in={expandedRow === log.id} timeout="auto" unmountOnExit>
                                                    <div style={{ padding: '10px', border: '1px solid #B1BACE' }}>
                                                        <pre>{JSON.stringify(log.context, null, 2)}</pre>
                                                    </div>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={versionList?.totalCount || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Paper>
    );
};

export default Logs;
