import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    TableSortLabel,
    CircularProgress,
} from '@mui/material';
import debounce from 'lodash/debounce';
import { getConfigurationsDraftListFromState, setConfigurationsDraftList } from '@/store/configurationsSlice';
import { useConfigurationsApi } from '@/api-client/configurations-api';
import { Link } from 'react-router-dom';
import CreateNewConfiguration from '@/modules/configurations/configuration/create-new-configuration';
import CustomTextField from '@/components/custom-text-field';
import CustomButton from '@/components/custom-button';
import ClearIcon from '@mui/icons-material/Clear';
import { useSnackbar } from 'notistack';

const DraftConfigurations: React.FC = () => {
    const { getConfigurationsDraftList, removeDraftConfigurationRemove } = useConfigurationsApi();
    const dispatch: AppDispatch = useDispatch();
    const configurationsList = useSelector(getConfigurationsDraftListFromState);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState<string>('id');
    const [sortDir, setSortDir] = useState<'asc' | 'desc'>('desc');
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchTerm(event.target.value);
    };

    const handleSortChange = (property: string): void => {
        const isAsc = sortBy === property && sortDir === 'asc';
        setSortDir(isAsc ? 'desc' : 'asc');
        setSortBy(property);
    };

    const fetchConfigurationsList = async (
        keyword: string,
        currentPage: number,
        currentRowsPerPage: number,
        currentSortBy: string,
        currentSortDir: string,
    ): Promise<void> => {
        setLoading(true);
        try {
            const list = await getConfigurationsDraftList({
                keyword,
                page: currentPage,
                perPage: currentRowsPerPage,
                sortBy: currentSortBy,
                sortDir: currentSortDir,
            });
            dispatch(setConfigurationsDraftList(list));
        } catch (error) {
            console.error('Error fetching configurations list:', error);
        } finally {
            setLoading(false);
        }
    };

    const debouncedFetchConfigurationsList = useCallback(
        debounce(
            (
                keyword: string,
                currentPage: number,
                currentRowsPerPage: number,
                currentSortBy: string,
                currentSortDir: string,
            ) => {
                fetchConfigurationsList(keyword, currentPage, currentRowsPerPage, currentSortBy, currentSortDir);
            },
            500,
        ),
        [],
    );

    useEffect(() => {
        debouncedFetchConfigurationsList(searchTerm, page + 1, rowsPerPage, sortBy, sortDir);
    }, [page, rowsPerPage, sortBy, sortDir]);

    const handleDelete = async (teamId: number): void => {
        try {
            await removeDraftConfigurationRemove(teamId);
            enqueueSnackbar(`Draft configuration deleted successfully`, {
                variant: 'success',
                preventDuplicate: true,
            });
            debouncedFetchConfigurationsList(searchTerm, page + 1, rowsPerPage, sortBy, sortDir);
        } catch (error) {
            enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            console.error('Error deleting draft configuration:', error);
        }
    };

    return (
        <div style={{ padding: '25px' }}>
            <Paper style={{ padding: '25px' }}>
                <CreateNewConfiguration />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        margin: '15px 0',
                        gap: '15px',
                    }}
                >
                    <CustomTextField label="Search" value={searchTerm} onChange={handleSearchChange} fullWidth />
                    <ClearIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setSearchTerm('');
                            debouncedFetchConfigurationsList('', 1, rowsPerPage, sortBy, sortDir);
                        }}
                    />
                    <CustomButton
                        label="Submit"
                        onClick={() => debouncedFetchConfigurationsList(searchTerm, 1, rowsPerPage, sortBy, sortDir)}
                    />
                </div>

                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '25px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {['id', 'displayName'].map((column) => (
                                            <TableCell style={{ border: '1px solid #030F2B' }} key={column}>
                                                {['id', 'displayName'].includes(column) ? (
                                                    <TableSortLabel
                                                        active={sortBy === column}
                                                        direction={sortBy === column ? sortDir : 'asc'}
                                                        onClick={() => handleSortChange(column)}
                                                    >
                                                        {column.charAt(0).toUpperCase() + column.slice(1)}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.charAt(0).toUpperCase() + column.slice(1)
                                                )}
                                            </TableCell>
                                        ))}
                                        <TableCell style={{ border: '1px solid #030F2B' }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {configurationsList.items.map((item) => (
                                        <TableRow key={item.id}>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>{item.id}</TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                {item.displayName}
                                            </TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                <Link to={`/configurations/draft/${item.id}`}>
                                                    <CustomButton label="Edit" />
                                                </Link>
                                                <CustomButton label="Delete" onClick={() => handleDelete(item.id)} />
                                                {/* <Link to={`/user/create`} state={team}>Clone</Link> */}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={configurationsList.totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </Paper>
        </div>
    );
};

export default DraftConfigurations;
