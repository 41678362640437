import React from 'react';
import NavbarItem from '@/components/navbar/components/navbar-item';
import Dropdown from '@/components/navbar/components/dropdown-menu';
import './navbar.scoped.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getUser } from '@/store/usersSlice';
import { AVAILABLE_ROLES } from '@/types';

const Navbar: React.FC = () => {
    const user = useSelector((state: RootState) => getUser(state));
    return (
        <div className="navbar">
            {/*<LocaleSelect />*/}
            <NavbarItem text="Home" link="/" />
            {user.email ? (
                user.role !== AVAILABLE_ROLES.ROLE_USER ? (
                    <>
                        <Dropdown
                            title="Configurations"
                            items={[
                                { text: 'All', link: '/configurations' },
                                { text: 'Draft', link: '/configurations/draft' },
                                { text: 'Categories', link: '/configurations/categories' },
                                { text: 'Uploads history', link: '/configurations/uploads' },
                            ]}
                        />
                        <Dropdown
                            title="User Management"
                            items={[
                                { text: 'Users', link: '/users' },
                                { text: 'Admins', link: '/admins' },
                                { text: 'Business Teams', link: '/business-teams' },
                                { text: 'Revoked', link: '/revoked' },
                            ]}
                        />
                        <NavbarItem text="Logs" link="/logs" />
                    </>
                ) : (
                    <>
                        <NavbarItem text="Configurations" link="/configurations" />
                        <NavbarItem text="Uploads history" link="/uploads" />
                    </>
                )
            ) : (
                ''
            )}
        </div>
    );
};

export default Navbar;
