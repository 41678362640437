import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, MenuItem, CircularProgress, FormControlLabel, Checkbox, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { getConfigurationsCurrentConfiguration, setCurrentConfigurationFileConfig } from '@/store/configurationsSlice';
import { AppDispatch } from '@/store';
import { useSnackbar } from 'notistack';
import { useConfigurationsApi } from '@/api-client/configurations-api';
import { useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomSelect from '@/components/custom-select';

interface Props {
    isDraft: boolean;
    configurationId: string | null;
}

const FileOptions: React.FC<Props> = ({ isDraft, configurationId }) => {
    const configuration = useSelector(getConfigurationsCurrentConfiguration);
    const dispatch: AppDispatch = useDispatch();
    const { updateDraftConfigurationFileOptions, updateConfigurationFileOptions } = useConfigurationsApi();
    const { id } = useParams<{ id: string }>();
    const { enqueueSnackbar } = useSnackbar();
    const hasMounted = useRef(false);
    const [statuses, setStatuses] = useState({
        encoding: null,
        delimiter: null,
        enclosure: null,
        escape: null,
        skipFirstLine: null,
        skipEmptyLines: null,
    });

    useEffect(() => {
        setStatuses({
            encoding: determineStatus(configuration.fileConfig.encoding),
            delimiter: determineStatus(configuration.fileConfig.delimiter),
            enclosure: determineStatus(configuration.fileConfig.enclosure),
            escape: determineStatus(configuration.fileConfig.escape),
            skipFirstLine: determineStatus(configuration.fileConfig.skipFirstLine),
            skipEmptyLines: determineStatus(configuration.fileConfig.skipEmptyLines),
        });
    }, [configuration]);

    const determineStatus = (field: string | null): string | null => {
        if (field?.loading) {
            return 'loading';
        } else if (field?.success) {
            return 'success';
        } else if (field?.error) {
            return 'error';
        }
        return null;
    };

    const debouncedApiCallFileOptions = useCallback(
        debounce(async (fields, configuration, isDraft) => {
            const updatedDetails = {};
            try {
                if (isDraft) {
                    await updateDraftConfigurationFileOptions(configurationId || id, fields);
                } else {
                    await updateConfigurationFileOptions(configurationId || id, fields);
                }
                Object.entries(configuration).forEach(([key, valueObj]) => {
                    if (valueObj?.loading) {
                        updatedDetails[key] = {
                            ...valueObj,
                            loading: false,
                            success: true,
                        };
                    } else {
                        updatedDetails[key] = valueObj;
                    }
                });

                dispatch(setCurrentConfigurationFileConfig(updatedDetails));
            } catch (error) {
                Object.entries(configuration).forEach(([key, valueObj]) => {
                    updatedDetails[key] = {
                        ...valueObj,
                        loading: false,
                        success: false,
                        error: false,
                    };
                });

                if (error) {
                    const violations = error.details?.violations?.violations;
                    const propertyPathMapping = {
                        encoding: 'encoding',
                        delimiter: 'delimiter',
                        enclosure: 'enclosure',
                        escape: 'escape',
                        skipFirstLine: 'skipFirstLine',
                        skipEmptyLines: 'skipFirstLine',
                    };
                    violations?.forEach((violation) => {
                        const { propertyPath } = violation;
                        const customPropertyPath = propertyPathMapping[propertyPath] || propertyPath;
                        enqueueSnackbar(`${violation.propertyPath}: ${violation.title}`, {
                            variant: 'error',
                            preventDuplicate: true,
                        });

                        if (updatedDetails[customPropertyPath]) {
                            updatedDetails[customPropertyPath] = {
                                ...updatedDetails[customPropertyPath],
                                error: true,
                            };
                        }
                    });
                }

                dispatch(setCurrentConfigurationFileConfig(updatedDetails));
            } finally {
                // console.log('finally');
            }
        }, 2000),
        [configurationId],
    );

    const handleFieldFileOptionsChange = (e): void => {
        const { name, type, value, checked } = e.target;

        const newValue = type === 'checkbox' ? checked : value;

        dispatch(
            setCurrentConfigurationFileConfig({
                ...configuration.fileConfig,
                [name]: { value: newValue, loading: true, success: false, error: false },
            }),
        );
    };

    useEffect(() => {
        if (hasMounted.current) {
            debouncedApiCallFileOptions(
                {
                    encoding: configuration.fileConfig?.encoding?.value,
                    delimiter: configuration.fileConfig?.delimiter?.value,
                    enclosure: configuration.fileConfig?.enclosure?.value,
                    escape: configuration.fileConfig?.escape?.value,
                    skipFirstLine: configuration.fileConfig?.skipFirstLine?.value,
                    skipEmptyLines: configuration.fileConfig?.skipEmptyLines?.value,
                },
                configuration.fileConfig,
                isDraft,
            );
        } else {
            hasMounted.current = true;
        }

        return () => {
            debouncedApiCallFileOptions.cancel();
        };
    }, [
        configuration.fileConfig?.encoding?.value,
        configuration.fileConfig?.delimiter?.value,
        configuration.fileConfig?.enclosure?.value,
        configuration.fileConfig?.escape?.value,
        configuration.fileConfig?.skipFirstLine?.value,
        configuration.fileConfig?.skipEmptyLines?.value,
    ]);

    return (
        <>
            <Grid item xs={12}>
                <h4>CSV File Configuration:</h4>
            </Grid>
            <Grid item xs={6}>
                <CustomSelect
                    required
                    label="Encoding"
                    status={statuses.encoding}
                    value={configuration.fileConfig.encoding?.value || ''}
                    onChange={handleFieldFileOptionsChange}
                    name="encoding"
                >
                    <MenuItem value={''}>None</MenuItem>
                    <MenuItem value={'utf-8'}>UTF-8</MenuItem>
                    <MenuItem value={'ascii'}>ASCII</MenuItem>
                    <MenuItem value={'iso-8859-1'}>ISO-8859-1</MenuItem>
                    <MenuItem value={'iso-8859-6'}>ISO-8859-6</MenuItem>
                    <MenuItem value={'iso-8859-15'}>ISO-8859-15</MenuItem>
                    <MenuItem value={'windows-1251'}>Windows-1251</MenuItem>
                    <MenuItem value={'windows-1252'}>Windows-1252</MenuItem>
                </CustomSelect>
            </Grid>
            <Grid item xs={6}>
                <CustomSelect
                    required
                    label="Enclosure"
                    status={statuses.enclosure}
                    value={configuration.fileConfig.enclosure?.value || ''}
                    onChange={handleFieldFileOptionsChange}
                    name="enclosure"
                >
                    <MenuItem value={''}>None</MenuItem>
                    <MenuItem value={'quotation-mark'}>&quot;</MenuItem>
                    <MenuItem value={'apostrophe'}>&apos;</MenuItem>
                </CustomSelect>
            </Grid>
            <Grid item xs={6}>
                <CustomSelect
                    required
                    label="Escape"
                    status={statuses.escape}
                    value={configuration.fileConfig.escape?.value || ''}
                    onChange={handleFieldFileOptionsChange}
                    name="escape"
                >
                    <MenuItem value={''}>None</MenuItem>
                    <MenuItem value={'back-slash'}>\</MenuItem>
                    <MenuItem value={'double-quote'}>&quot;</MenuItem>
                    <MenuItem value={'apostrophe'}>&apos;</MenuItem>
                </CustomSelect>
            </Grid>
            <Grid item xs={6}>
                <CustomSelect
                    required
                    label="Delimiter"
                    status={statuses.delimiter}
                    value={configuration.fileConfig.delimiter?.value || ''}
                    onChange={handleFieldFileOptionsChange}
                    name="delimiter"
                >
                    <MenuItem value={''}>None</MenuItem>
                    <MenuItem value={'comma'}>,</MenuItem>
                    <MenuItem value={'semicolon'}>;</MenuItem>
                    <MenuItem value={'colon'}>:</MenuItem>
                    <MenuItem value={'pipe'}>|</MenuItem>
                </CustomSelect>
            </Grid>
            <Grid item xs={6}>
                <Box mt={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={configuration.fileConfig?.skipFirstLine?.value || false}
                                onChange={handleFieldFileOptionsChange}
                                name="skipFirstLine"
                                color="primary"
                                disabled={configuration.fileConfig?.skipFirstLine?.loading}
                            />
                        }
                        label="Skip the first line"
                    />
                    {configuration.fileConfig?.skipFirstLine?.loading && <CircularProgress size={24} />}
                    {!configuration.fileConfig?.skipFirstLine?.loading &&
                        configuration.fileConfig?.skipFirstLine?.success && <CheckCircleIcon color="success" />}
                    {!configuration.fileConfig?.skipFirstLine?.loading &&
                        configuration.fileConfig?.skipFirstLine?.error && <HighlightOffIcon color="error" />}
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box mt={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={configuration.fileConfig?.skipEmptyLines?.value || false}
                                onChange={handleFieldFileOptionsChange}
                                name="skipEmptyLines"
                                color="primary"
                                disabled={configuration.fileConfig?.skipEmptyLines?.loading}
                            />
                        }
                        label="Skip empty lines"
                    />
                    {configuration.fileConfig?.skipEmptyLines?.loading && <CircularProgress size={24} />}
                    {!configuration.fileConfig?.skipEmptyLines?.loading &&
                        configuration.fileConfig?.skipEmptyLines?.success && <CheckCircleIcon color="success" />}
                    {!configuration.fileConfig?.skipEmptyLines?.loading &&
                        configuration.fileConfig?.skipEmptyLines?.error && <HighlightOffIcon color="error" />}
                </Box>
            </Grid>
        </>
    );
};

export default FileOptions;
