import { useApiClient } from '@/api-client/okta-api-client-controller';
import { User, UsersList } from '@/types';

// interface PaginatedResponse<T> {
//     items: T[];
//     currentPageNumber: number;
//     numItemsPerPage: number;
//     totalCount: number;
// }

interface UserListParams {
    term?: string;
    businessTeam?: string;
    page?: number;
    perPage?: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useUsersApi = () => {
    const { apiClient } = useApiClient();

    const buildQueryString = (params: UserListParams): string => {
        const filteredParams: UserListParams = Object.entries(params)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, value]) => value !== undefined && value !== '')
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        const queryString = Object.entries(filteredParams)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');

        return queryString;
    };

    const getUserById = async (userId: string): Promise<User> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<User>(`/api/v1/user/${userId}`, { method: 'GET' }, `getUserById-${userId}`);
    };

    const createUser = async (data: User): Promise<User> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<User>(
            '/api/v1/user/create',
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'createUser',
        );
    };

    const getUserInfo = async (): Promise<User> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<User>('/api/v1/user-info', { method: 'GET' }, 'getUserInfo');
    };

    const getUsersList = async (params: UserListParams): Promise<UsersList> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<UsersList>(`/api/v1/users/list?${queryString}`, { method: 'GET' }, 'getUsersList');
    };

    const getAdminsList = async (params: UserListParams): Promise<UsersList> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<UsersList>(`/api/v1/admins/list?${queryString}`, { method: 'GET' }, 'getUsersList');
    };

    const restoreUser = async (userId: number): Promise<User> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<User>(`/api/v1/user/${userId}/restore`, { method: 'POST' }, 'restoreUser');
    };

    const revokeUser = async (userId: string): Promise<User> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<User>(`/api/v1/user/${userId}/revoke`, { method: 'POST' }, 'revokeUser');
    };

    const getRevokedUsersList = async (params: UserListParams): Promise<UsersList> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<UsersList>(
            `/api/v1/revoked-users/list?${queryString}`,
            { method: 'GET' },
            'getRevokedUsersList',
        );
    };

    const updateUser = async (userId: number, data: User): Promise<User> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<User>(
            `/api/v1/user/${userId}/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            `updateUser-${userId}`,
        );
    };

    return {
        getUserById,
        createUser,
        getUserInfo,
        getUsersList,
        getAdminsList,
        restoreUser,
        revokeUser,
        getRevokedUsersList,
        updateUser,
    };
};
