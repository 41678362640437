import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    TableSortLabel,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
} from '@mui/material';
import { useConfigurationsApi } from '@/api-client/configurations-api';
import debounce from 'lodash/debounce';
import { useParams } from 'react-router-dom';
import { getConfigurationVersionListFromState, setConfigurationVersionList } from '@/store/configurationsSlice';
import { ConfigurationResponse } from '@/types';
import CustomButton from '@/components/custom-button';

interface Props {
    configurationId: string | null;
    transformToConfiguration: (configuration: unknown) => ConfigurationResponse;
    setIsConfigurationsSet: React.Dispatch<React.SetStateAction<boolean>>;
}

const VersionHistory: React.FC<Props> = ({ configurationId, transformToConfiguration, setIsConfigurationsSet }) => {
    const { getConfigurationVersionList, postConfigurationVersionActivate } = useConfigurationsApi();
    const { id } = useParams<{ id: string }>();
    const dispatch: AppDispatch = useDispatch();
    const versionList = useSelector((state: RootState) => getConfigurationVersionListFromState(state));
    const [page, setPage] = React.useState(0);
    const [sortBy, setSortBy] = useState<string>('activatedAt');
    const [sortDir, setSortDir] = useState<'asc' | 'desc'>('desc');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openActivate, setActivateOpen] = useState(false);
    const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null);
    const [loading, setLoading] = useState(true);

    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSortChange = (property: string): void => {
        const isAsc = sortBy === property && sortDir === 'asc';
        setSortDir(isAsc ? 'desc' : 'asc');
        setSortBy(property);
    };

    const debouncedFetchConfigurationVersionList = useCallback(
        debounce((currentPage: number, currentRowsPerPage: number, currentSortBy: string, currentSortDir: string) => {
            fetchConfigurationVersion(currentPage, currentRowsPerPage, currentSortBy, currentSortDir);
        }, 500),
        [],
    );

    useEffect(() => {
        debouncedFetchConfigurationVersionList(page + 1, rowsPerPage, sortBy, sortDir);
    }, [page, rowsPerPage, sortBy, sortDir]);

    const fetchConfigurationVersion = async (
        currentPage: number,
        currentRowsPerPage: number,
        currentSortBy: string,
        currentSortDir: string,
    ): Promise<void> => {
        setLoading(true);
        try {
            const list = await getConfigurationVersionList(
                {
                    page: currentPage,
                    perPage: currentRowsPerPage,
                    sortBy: currentSortBy,
                    sortDir: currentSortDir,
                },
                configurationId || id,
            );
            dispatch(setConfigurationVersionList(list));
            setIsConfigurationsSet(false);
        } catch (error) {
            console.error('Error fetching business teams list:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenActivateModal = (teamId: number): void => {
        setSelectedTeamId(teamId);
        setActivateOpen(true);
    };

    const handleCloseActivateModal = (): void => {
        setActivateOpen(false);
        setSelectedTeamId(null);
    };

    const handleConfirmActivate = async (): Promise<void> => {
        if (selectedTeamId === null) return;

        try {
            const dataConfiguration = await postConfigurationVersionActivate(selectedTeamId);
            transformToConfiguration(dataConfiguration);
            debouncedFetchConfigurationVersionList(1, rowsPerPage, sortBy, sortDir);
            handleCloseActivateModal();
        } catch (error) {
            console.error('Error activating configuration version:', error);
            handleCloseActivateModal();
        }
    };

    return (
        <Paper style={{ width: '100%' }}>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', padding: '25px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <TableContainer style={{ padding: '25px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {['activatedAt', 'version', 'description', 'editor'].map((column) => (
                                        <TableCell style={{ border: '1px solid #030F2B' }} key={column}>
                                            <h6>
                                                {['activatedAt', 'version'].includes(column) ? (
                                                    <TableSortLabel
                                                        active={sortBy === column}
                                                        direction={sortBy === column ? sortDir : 'asc'}
                                                        onClick={() => handleSortChange(column)}
                                                    >
                                                        {column.charAt(0).toUpperCase() + column.slice(1)}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.charAt(0).toUpperCase() + column.slice(1)
                                                )}
                                            </h6>
                                        </TableCell>
                                    ))}
                                    <TableCell style={{ border: '1px solid #030F2B' }}>
                                        <h6>Actions</h6>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {versionList.items.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell style={{ border: '1px solid #B1BACE' }}>
                                            {item.activatedAt}
                                        </TableCell>
                                        <TableCell style={{ border: '1px solid #B1BACE' }}>{item.version}</TableCell>
                                        <TableCell style={{ border: '1px solid #B1BACE' }}>
                                            {item.description}
                                        </TableCell>
                                        <TableCell style={{ border: '1px solid #B1BACE' }}>
                                            {item?.lastEditor?.firstName} {item?.lastEditor?.lastName}
                                        </TableCell>
                                        <TableCell style={{ border: '1px solid #B1BACE' }}>
                                            {item.status === 'active' ? (
                                                'Active'
                                            ) : (
                                                <CustomButton
                                                    label="Activate"
                                                    onClick={() => handleOpenActivateModal(item.id)}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={versionList.totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <Dialog open={openActivate} onClose={handleCloseActivateModal}>
                        <DialogTitle>Confirm Activation</DialogTitle>
                        <DialogContent>Do you really want to activate this configuration version?</DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseActivateModal} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleConfirmActivate} color="primary">
                                Activate
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </Paper>
    );
};

export default VersionHistory;
