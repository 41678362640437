import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    MenuItem,
    TableSortLabel,
    CircularProgress,
} from '@mui/material';
import debounce from 'lodash/debounce';
import { useParams } from 'react-router-dom';
import { useFilesApi } from '@/api-client/files-api';
import {
    getConfigurationUploadHistoryListFromState,
    setConfigurationUploadHistoryList,
} from '@/store/configurationsSlice';
import { useSnackbar } from 'notistack';
import { getUsersFullListFromState } from '@/store/usersSlice';
import { useUploadHistoryApi } from '@/api-client/upload-history-api';
import CustomSelect from '@/components/custom-select';
import CustomTextField from '@/components/custom-text-field';
import CustomButton from '@/components/custom-button';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from '@mui/styles';

interface Props {
    withoutConfiguration?: boolean;
    userId?: number;
    selectedTeams?: Array<number>;
}

const useStyles = makeStyles({
    statusCell: {
        border: '1px solid #B1BACE',
    },
    success: {
        color: 'green',
    },
    failed: {
        color: 'red',
    },
    inProcess: {
        color: 'orange',
    },
});

const UploadHistoryList: React.FC<Props> = ({ withoutConfiguration, userId, selectedTeams }) => {
    const { getUploadHistoryList, getUploadHistoryListById } = useUploadHistoryApi();
    const { downloadFile } = useFilesApi();
    const dispatch: AppDispatch = useDispatch();
    const uploadHistoryList = useSelector((state: RootState) => getConfigurationUploadHistoryListFromState(state));
    const userList = useSelector((state: RootState) => getUsersFullListFromState(state));

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchParams, setSearchParams] = useState({
        status: '',
        userId: '',
        dateFrom: '',
        dateTo: '',
        sortBy: 'documentName',
        sortDir: 'desc',
    });
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const classes = useStyles();

    const getStatusClass = (status: string): string => {
        switch (status) {
            case 'done':
            case 'sent':
                return classes.success;
            case 'error':
            case 'processing_error':
            case 'sending_fail':
                return classes.failed;
            case 'in_process':
                return classes.inProcess;
            default:
                return '';
        }
    };

    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchParamsChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
        setSearchParams({ ...searchParams, [event.target.name as string]: event.target.value });
    };

    const handleSortChange = (property: string): void => {
        const isAsc = searchParams.sortBy === property && searchParams.sortDir === 'asc';
        setSearchParams({ ...searchParams, sortBy: property, sortDir: isAsc ? 'desc' : 'asc' });
    };

    const fetchUploadHistoryList = async (
        currentSearchParams: typeof searchParams,
        currentPage: number,
        currentRowsPerPage: number,
        selectedTeams: Array<string>,
    ): Promise<void> => {
        setLoading(true);
        try {
            let list;
            if (withoutConfiguration) {
                const params: Record<string, unknown> = {
                    ...currentSearchParams,
                    page: currentPage,
                    perPage: currentRowsPerPage,
                };

                if (selectedTeams?.length > 0) {
                    params['teamIds[]'] = selectedTeams;
                }

                if (userId) {
                    params['userId'] = userId;
                }

                list = await getUploadHistoryList(params);
            } else {
                list = await getUploadHistoryListById(id, {
                    ...currentSearchParams,
                    page: currentPage,
                    perPage: currentRowsPerPage,
                });
            }
            dispatch(setConfigurationUploadHistoryList(list));
        } catch (error) {
            console.error('Error fetching upload history list:', error);
        } finally {
            setLoading(false);
        }
    };

    const debouncedFetchUploadHistoryList = useCallback(
        debounce((currentSearchParams, currentPage, currentRowsPerPage, selectedTeams) => {
            fetchUploadHistoryList(currentSearchParams, currentPage, currentRowsPerPage, selectedTeams);
        }, 500),
        [],
    );

    useEffect(() => {
        debouncedFetchUploadHistoryList(searchParams, page + 1, rowsPerPage, selectedTeams);
    }, [searchParams, page, rowsPerPage, selectedTeams]);

    const handleDownloadFile = async (uploadHistory): Promise<void> => {
        if (uploadHistory?.fileResource) {
            try {
                const { uuid, extension } = uploadHistory.fileResource;
                const fileData = await downloadFile(uuid, extension);

                const url = window.URL.createObjectURL(new Blob([fileData]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${uploadHistory.fileResource.originalName}.${extension}`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            } catch (error) {
                enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            }
        }
    };

    return (
        <Paper style={{ width: '100%' }}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    gap: '15px',
                    padding: '25px',
                }}
            >
                <CustomSelect
                    label="Status"
                    value={searchParams.status}
                    onChange={handleSearchParamsChange}
                    name="status"
                    fullWidth
                >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="in_process">In Process</MenuItem>
                    <MenuItem value="error">Error</MenuItem>
                    <MenuItem value="processing_error">Processing Error</MenuItem>
                    <MenuItem value="scheduled">Scheduled</MenuItem>
                    <MenuItem value="done">Done</MenuItem>
                    <MenuItem value="sending_fail">Sending Fail</MenuItem>
                    <MenuItem value="sent">Sent</MenuItem>
                </CustomSelect>
                {!userId ? (
                    <CustomSelect
                        label="User"
                        value={searchParams.userId}
                        onChange={handleSearchParamsChange}
                        name="userId"
                        fullWidth
                    >
                        <MenuItem value="">All</MenuItem>
                        {userList.items.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                                {user.firstName} {user.lastName}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                ) : (
                    ''
                )}
                <CustomTextField
                    label="Start Date"
                    type="date"
                    name="dateFrom"
                    value={searchParams.dateFrom}
                    onChange={handleSearchParamsChange}
                    fullWidth
                />
                <CustomTextField
                    label="End Date"
                    type="date"
                    name="dateTo"
                    value={searchParams.dateTo}
                    onChange={handleSearchParamsChange}
                    fullWidth
                />
                <ClearIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setSearchParams({
                            status: '',
                            userId: '',
                            dateFrom: '',
                            dateTo: '',
                            sortBy: 'date',
                            sortDir: 'desc',
                        });
                        debouncedFetchUploadHistoryList(
                            { status: '', userId: '', dateFrom: '', dateTo: '', sortBy: 'date', sortDir: 'desc' },
                            1,
                            rowsPerPage,
                            selectedTeams,
                        );
                    }}
                />
            </div>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', padding: '25px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <TableContainer style={{ padding: '25px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {[
                                        'documentName',
                                        'configurationName',
                                        'status',
                                        'version',
                                        'date',
                                        'user',
                                        'correctionsNumber',
                                    ].map((column) => (
                                        <TableCell style={{ border: '1px solid #030F2B' }} key={column}>
                                            <h6>
                                                <TableSortLabel
                                                    active={searchParams.sortBy === column}
                                                    direction={
                                                        searchParams.sortBy === column ? searchParams.sortDir : 'asc'
                                                    }
                                                    onClick={() => handleSortChange(column)}
                                                >
                                                    {column.charAt(0).toUpperCase() + column.slice(1)}
                                                </TableSortLabel>
                                            </h6>
                                        </TableCell>
                                    ))}
                                    <TableCell style={{ border: '1px solid #030F2B' }}>
                                        <h6>Actions</h6>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {uploadHistoryList.items.map((uploadHistory) => (
                                    <TableRow key={uploadHistory.id}>
                                        <TableCell style={{ border: '1px solid #B1BACE' }}>
                                            {uploadHistory.fileResource.originalName}
                                        </TableCell>
                                        <TableCell
                                            className={`${classes.statusCell} ${getStatusClass(uploadHistory.status || '')}`}
                                        >
                                            {uploadHistory.status}
                                        </TableCell>
                                        <TableCell style={{ border: '1px solid #B1BACE' }}>
                                            {uploadHistory.status}
                                        </TableCell>
                                        <TableCell style={{ border: '1px solid #B1BACE' }}>
                                            {uploadHistory.versionResource.version}
                                        </TableCell>
                                        <TableCell style={{ border: '1px solid #B1BACE' }}>
                                            {new Date(uploadHistory.fileResource.createdAt).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell style={{ border: '1px solid #B1BACE' }}>
                                            {uploadHistory.userResource.firstName} {uploadHistory.userResource.lastName}
                                        </TableCell>
                                        <TableCell style={{ border: '1px solid #B1BACE' }}>
                                            {uploadHistory.correctionsNumber}
                                        </TableCell>
                                        <TableCell style={{ border: '1px solid #B1BACE' }}>
                                            <CustomButton
                                                label="Download File"
                                                onClick={() => handleDownloadFile(uploadHistory)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={uploadHistoryList.totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Paper>
    );
};

export default UploadHistoryList;
