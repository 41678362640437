import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getUser } from '@/store/usersSlice';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import ArrowDown from '@/icons/Arrow-Down.svg';

const AccountMenu: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const user = useSelector((state: RootState) => getUser(state));
    const { oktaAuth } = useOktaAuth();

    const onLogout = async (): Promise<void> => {
        // oktaAuth.tokenManager.clear();
        await oktaAuth.signOut();
    };

    useEffect(() => {
        console.log('user:', user);
    }, [user]);

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'end' }}>
                <Tooltip
                    title="Account settings"
                    sx={[
                        {
                            color: 'transparent',
                            '&:hover,&:focus': {
                                backgroundColor: 'transparent',
                                color: 'transparent',
                            },
                        },
                    ]}
                >
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={[
                            {
                                ml: 2,
                                color: 'transparent',
                                '&:hover,&:focus': {
                                    backgroundColor: 'transparent',
                                    color: 'transparent',
                                },
                            },
                        ]}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 40, height: 40, backgroundColor: '#030F2B' }}>
                            <div style={{ fontSize: '14px' }}>
                                {user.firstName.substring(0, 1).toUpperCase()}
                                {user.lastName.substring(0, 1).toUpperCase()}
                            </div>
                        </Avatar>
                        <img style={{ marginLeft: '5px' }} src={ArrowDown} alt="arrow-down" />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose}>
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
                    <div onClick={onLogout}>
                        <Logout fontSize="small" />
                        Logout
                    </div>
                </MenuItem>
            </Menu>
        </>
    );
};

export default AccountMenu;
