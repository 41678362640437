import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from '@/store';
import { useNavigate } from 'react-router-dom';

import '@/styles/app.scss';
import Page from '@/modules/page';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import AppRoutes from '@/app-routes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getDefaultLanguage, initializeI18n } from '@/i18n';
import { ApiClientProvider } from '@/api-client/okta-api-client-controller';
import { getOktaConfig } from '@/okta/config';
import { SnackbarProvider } from 'notistack';

const defaultLanguage = getDefaultLanguage(store.getState());

initializeI18n(defaultLanguage);

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: 'LVMHSans-Regular, sans-serif',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const useRestoreOriginalUri = (): ((_oktaAuth: OktaAuth, originalUri: string | undefined) => void) => {
    const navigate = useNavigate();

    return (_oktaAuth: OktaAuth, originalUri: string | undefined): void => {
        navigate(toRelativeUrl(originalUri ?? '/', window.location.origin));
    };
};

const App: React.FC = () => {
    const [oktaAuth, setOktaAuth] = useState<OktaAuth | null>(null);
    const navigate = useNavigate();

    const restoreOriginalUri = useRestoreOriginalUri();

    useEffect(() => {
        const fetchOktaConfig = async (): Promise<void> => {
            try {
                const { oidc } = await getOktaConfig();
                const authClient = new OktaAuth(oidc);
                setOktaAuth(authClient);
            } catch (error) {
                console.error('Error loading Okta config:', error);
                // Handle error, e.g., redirect to an error page
            }
        };

        fetchOktaConfig();
    }, [navigate]);

    if (!oktaAuth) {
        return <div>Loading...</div>;
    }

    return (
        <Provider store={store}>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <ApiClientProvider>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider preventDuplicate maxSnack={10} autoHideDuration={10000}>
                            <Page>
                                <AppRoutes />
                            </Page>
                        </SnackbarProvider>
                    </ThemeProvider>
                </ApiClientProvider>
            </Security>
        </Provider>
    );
};

export default App;
