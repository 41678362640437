import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './reducer';

export const store = configureStore({
    reducer: rootReducer,
    devTools: !process.env.IS_PRODUCTION,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
