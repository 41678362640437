import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import UploadHistoryList from '@/modules/configurations/business-team/upload-history-list';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getUser } from '@/store/usersSlice';
import { MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomSelect from '@/components/custom-select';

const Uploads: React.FC = () => {
    const [value, setValue] = useState('1');
    const user = useSelector((state: RootState) => getUser(state));
    const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
    const [lastItemSelected, setLastItemSelected] = useState(false);

    // Watch for changes in user.businessTeams
    useEffect(() => {
        // Initialize selectedTeams with all team IDs when user.businessTeams changes
        if (user.businessTeams.length > 0) {
            setSelectedTeams(user.businessTeams.map((team) => team.id.toString()));
        }
    }, [user.businessTeams]);

    const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
        setValue(newValue);
    };

    // Watch for changes in selectedTeams to determine if last item is selected
    useEffect(() => {
        if (selectedTeams.length === 1 && user.businessTeams.length > 0) {
            setLastItemSelected(true);
        } else {
            setLastItemSelected(false);
        }
    }, [selectedTeams, user.businessTeams]);

    const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        const selectedIds = event.target.value as string[];

        // Check if the last item is selected and prevent deselecting if it is
        if (lastItemSelected && selectedIds.length === 0) {
            // Do nothing or handle accordingly (e.g., show a message)
            return;
        }

        setSelectedTeams(selectedIds);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box style={{ background: '#fff' }}>
                    <TabList onChange={handleChange} aria-label="tabs example">
                        <Tab label="My Uploads" value="1" />
                        <Tab label="Business Team Uploads" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    {user.id ? <UploadHistoryList userId={user.id} withoutConfiguration={true} /> : ''}
                </TabPanel>
                <TabPanel value="2">
                    <div style={{ background: '#fff', padding: '25px' }}>
                        <CustomSelect
                            label="Select Business Teams"
                            name="userId"
                            value={selectedTeams}
                            onChange={handleSelectChange}
                            multiple
                            fullWidth
                        >
                            {user.businessTeams.map((team) => (
                                <MenuItem key={team.id} value={team.id.toString()}>
                                    {team.name}
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </div>
                    <UploadHistoryList selectedTeams={selectedTeams} withoutConfiguration={true} />
                </TabPanel>
            </TabContext>
        </Box>
    );
};

export default Uploads;
