import { useApiClient } from '@/api-client/okta-api-client-controller';
import { BusinessTeam, BusinessTeamsList } from '@/types';

interface BusinessTeamListParams {
    isActive?: boolean;
    page?: number;
    perPage?: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useBusinessTeamsApi = () => {
    const { apiClient } = useApiClient();

    const buildQueryString = (params: BusinessTeamListParams): string => {
        const filteredParams: BusinessTeamListParams = Object.entries(params)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, value]) => value !== undefined && value !== '')
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        const queryString = Object.entries(filteredParams)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');

        return queryString;
    };

    const createBusinessTeam = async (data: Partial<BusinessTeam>): Promise<BusinessTeam> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<BusinessTeam>(
            '/api/v1/business-team/create',
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'createBusinessTeam',
        );
    };

    const getBusinessTeamsList = async (params: BusinessTeamListParams): Promise<BusinessTeamsList> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<BusinessTeamsList>(
            `/api/v1/business-teams/list?${queryString}`,
            { method: 'GET' },
            'getBusinessTeamsList',
        );
    };

    const restoreBusinessTeam = async (businessTeamId: number): Promise<BusinessTeam> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<BusinessTeam>(
            `/api/v1/business-team/${businessTeamId}/restore`,
            { method: 'POST' },
            'restoreBusinessTeam',
        );
    };

    const revokeBusinessTeam = async (businessTeamId: number): Promise<BusinessTeam> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<BusinessTeam>(
            `/api/v1/business-team/${businessTeamId}/revoke`,
            { method: 'POST' },
            'revokeBusinessTeam',
        );
    };

    const updateBusinessTeam = async (businessTeamId: number, data: Partial<BusinessTeam>): Promise<BusinessTeam> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<BusinessTeam>(
            `/api/v1/business-team/${businessTeamId}/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'updateBusinessTeam',
        );
    };

    return {
        createBusinessTeam,
        getBusinessTeamsList,
        restoreBusinessTeam,
        revokeBusinessTeam,
        updateBusinessTeam,
    };
};
