import React, { useEffect, useRef, useState, ChangeEvent } from 'react';
import { Grid, Button, IconButton, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { Delete, Add, Edit, Check } from '@mui/icons-material';
import { getConfigurationsCurrentConfiguration, setCurrentConfigurationColumns } from '@/store/configurationsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store';
import { useConfigurationsApi } from '@/api-client/configurations-api';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CustomTextField from '@/components/custom-text-field';
import CustomSelect from '@/components/custom-select';

interface Props {
    isDraft: boolean;
    configurationId: string | null;
}

const ColumnsTable: React.FC<Props> = ({ isDraft, configurationId }) => {
    const configuration = useSelector(getConfigurationsCurrentConfiguration);
    const dispatch: AppDispatch = useDispatch();
    const {
        postDraftConfigurationColumns,
        updateDraftConfigurationColumns,
        removeDraftConfigurationColumns,
        removeConfigurationColumns,
        updateConfigurationColumns,
        postConfigurationColumns,
    } = useConfigurationsApi();
    const { id } = useParams<{ id: string }>();
    const [localColumns, setLocalColumns] = useState([...configuration.columns]);
    const localColumnsRef = useRef(localColumns);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setLocalColumns([...configuration.columns]);
        localColumnsRef.current = [...configuration.columns];
    }, [configuration.columns]);

    const remapColumnObject = (column): unknown => ({
        name: column.name,
        position: Number(column.position),
        type: column.type,
        required: column.required,
        default: column.default,
        nullable: column.nullable,
        ruleIds: column.ruleIds,
        bigQueryField: column?.bigQueryField?.length === 0 ? null : column.bigQueryField,
    });

    const handleAddColumn = (): void => {
        dispatch(
            setCurrentConfigurationColumns([
                ...configuration.columns,
                {
                    id: null,
                    position: configuration?.columns?.length + 1,
                    name: '',
                    type: 'string',
                    required: false,
                    default: null,
                    nullable: false,
                    ruleIds: [],
                    bigQueryField: [],
                    isEditing: true,
                    loading: false,
                    error: false,
                },
            ]),
        );
    };

    const handleDeleteColumn = async (index: number): Promise<void> => {
        const column = configuration.columns[index];
        if (column.id) {
            try {
                if (isDraft) {
                    await removeDraftConfigurationColumns(configurationId || id, column.id);
                } else {
                    await removeConfigurationColumns(configurationId || id, column.id);
                }
            } catch (error) {
                console.error('Failed to delete column:', error);
            }
        }
        dispatch(setCurrentConfigurationColumns(configuration.columns.filter((_, i) => i !== index)));
    };

    const handleColumnChange = (index: number, field: string, value: unknown): void => {
        const newColumns = localColumns.map((column, i) => (i === index ? { ...column, [field]: value } : column));

        setLocalColumns(newColumns);
        localColumnsRef.current = newColumns;
        dispatch(setCurrentConfigurationColumns(newColumns));
    };

    const handleEditColumn = (index: number): void => {
        const updatedColumns = localColumns.map((column, i) => (i === index ? { ...column, isEditing: true } : column));
        setLocalColumns(updatedColumns);
        dispatch(setCurrentConfigurationColumns(updatedColumns));
    };

    const handleSaveColumn = async (index: number): Promise<void> => {
        const updatedColumns = localColumns.map((column, i) =>
            i === index ? { ...column, isEditing: false, loading: true, error: false } : column,
        );
        setLocalColumns(updatedColumns);
        dispatch(setCurrentConfigurationColumns(updatedColumns));

        const column = updatedColumns[index];
        const columnData = remapColumnObject(column);

        try {
            if (column.id) {
                if (isDraft) {
                    await updateDraftConfigurationColumns(configurationId || id, column.id, columnData);
                } else {
                    await updateConfigurationColumns(configurationId || id, column.id, columnData);
                }
            } else {
                const response = isDraft
                    ? await postDraftConfigurationColumns(configurationId || id, columnData)
                    : await postConfigurationColumns(configurationId || id, columnData);

                const newColumn = response.columns.find(
                    (col) => col.name === column.name && col.position === column.position && col.type === column.type,
                );
                const updatedColumnsAfterPost = localColumns.map((column, i) =>
                    i === index ? { ...column, ...newColumn, isEditing: false, loading: false, error: false } : column,
                );
                setLocalColumns(updatedColumnsAfterPost);
                dispatch(setCurrentConfigurationColumns(updatedColumnsAfterPost));
                return;
            }

            const updatedColumnsAfterSave = localColumns.map((column, i) =>
                i === index ? { ...column, loading: false, error: false, isEditing: false } : column,
            );
            setLocalColumns(updatedColumnsAfterSave);
            dispatch(setCurrentConfigurationColumns(updatedColumnsAfterSave));
        } catch (error) {
            console.error(error);
            const updatedColumnsAfterError = localColumns.map((column, i) => {
                if (i === index) {
                    const errorFields: Record<string, string> = {};
                    if (error.details?.violations) {
                        error.details.violations.violations.forEach((violation: unknown) => {
                            const errorField = violation.propertyPath;
                            errorFields[errorField] = violation.title;
                            enqueueSnackbar(`${errorField}: ${violation.title}`, {
                                variant: 'error',
                                preventDuplicate: true,
                            });
                        });
                    } else if (error.details?.exception) {
                        const errorMessage = error.details.exception.message;
                        enqueueSnackbar(errorMessage, {
                            variant: 'error',
                            preventDuplicate: true,
                        });
                    }
                    return { ...column, loading: false, error: true };
                }
                return column;
            });

            setLocalColumns(updatedColumnsAfterError);
            dispatch(setCurrentConfigurationColumns(updatedColumnsAfterError));
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <h4>Columns *:</h4>
            </Grid>
            <Grid item xs={12}>
                <div style={{ position: 'relative' }}>
                    <Button
                        style={{
                            position: 'absolute',
                            bottom: '0',
                            right: '0',
                            zIndex: '11',
                            height: '100%',
                            width: '150px',
                            border: '1px solid #54616D',
                            borderRadius: '0',
                            color: '#54616D',
                        }}
                        variant="outlined"
                        startIcon={<Add />}
                        onClick={handleAddColumn}
                    >
                        Add Column
                    </Button>
                    <div style={{ overflow: 'auto', marginRight: '150px' }}>
                        <table>
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            position: 'sticky',
                                            left: '0',
                                            background: '#fff',
                                            zIndex: '10',
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <div style={{ width: '150px', height: '60px' }}></div>
                                    </th>
                                    {localColumns.map((item, index) => (
                                        <th
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                            key={index}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: '60px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <IconButton onClick={() => handleDeleteColumn(index)}>
                                                        <Delete />
                                                    </IconButton>
                                                </div>
                                                {item.isEditing ? (
                                                    <IconButton onClick={() => handleSaveColumn(index)}>
                                                        <Check />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton onClick={() => handleEditColumn(index)}>
                                                        <Edit />
                                                    </IconButton>
                                                )}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {[
                                    'position',
                                    'name',
                                    'type',
                                    'required',
                                    'default',
                                    'nullable',
                                    'ruleIds',
                                    'bigQueryField',
                                ].map((field) => (
                                    <tr key={field}>
                                        <td
                                            style={{
                                                position: 'sticky',
                                                left: '0',
                                                background: '#fff',
                                                zIndex: '10',
                                                verticalAlign: 'middle',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '150px',
                                                    height: '60px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <h6 style={{ fontFamily: 'LVMHSans-ExtraLight' }}>
                                                    {field.charAt(0).toUpperCase() + field.slice(1)}
                                                    {(field === 'name' ||
                                                        field === 'default' ||
                                                        field === 'bigQueryField') &&
                                                        ' *'}
                                                </h6>
                                            </div>
                                        </td>
                                        {localColumns.map((column, index) => (
                                            <td
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                                key={index}
                                            >
                                                <div
                                                    style={{
                                                        width: '150px',
                                                        height: '60px',
                                                        padding: '0 1px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {field === 'required' || field === 'nullable' ? (
                                                        <FormControlLabel
                                                            style={{ margin: 0 }}
                                                            control={
                                                                <Checkbox
                                                                    checked={column[field]}
                                                                    onChange={(event) =>
                                                                        handleColumnChange(
                                                                            index,
                                                                            field,
                                                                            event.target.checked,
                                                                        )
                                                                    }
                                                                    disabled={!column.isEditing}
                                                                    style={{ color: column.error ? 'red' : undefined }}
                                                                />
                                                            }
                                                            label=""
                                                        />
                                                    ) : field === 'type' ? (
                                                        <CustomSelect
                                                            status={column.error ? 'error' : null}
                                                            value={column[field] || []}
                                                            onChange={(event) =>
                                                                handleColumnChange(index, field, event.target.value)
                                                            }
                                                            disabled={!column.isEditing}
                                                            fullWidth={true}
                                                        >
                                                            <MenuItem value="string">String</MenuItem>
                                                            <MenuItem value="int">Integer</MenuItem>
                                                            <MenuItem value="float">Float</MenuItem>
                                                        </CustomSelect>
                                                    ) : field === 'ruleIds' ? (
                                                        <CustomSelect
                                                            status={column.error ? 'error' : null}
                                                            multiple
                                                            disabled={!column.isEditing}
                                                            value={column.ruleIds || []}
                                                            onChange={(e) =>
                                                                handleColumnChange(index, 'ruleIds', e.target.value)
                                                            }
                                                            fullWidth={true}
                                                        >
                                                            {configuration.rulesRegular.map(
                                                                (rule, i) =>
                                                                    rule.id && (
                                                                        <MenuItem key={i} value={rule.id}>
                                                                            {rule.name}
                                                                        </MenuItem>
                                                                    ),
                                                            )}
                                                            {configuration.rulesCustom.map(
                                                                (rule, i) =>
                                                                    rule.id && (
                                                                        <MenuItem key={i} value={rule.id}>
                                                                            {rule.name}
                                                                        </MenuItem>
                                                                    ),
                                                            )}
                                                        </CustomSelect>
                                                    ) : field === 'bigQueryField' ? (
                                                        <CustomSelect
                                                            status={column.error ? 'error' : null}
                                                            disabled={!column.isEditing}
                                                            value={column.bigQueryField || []}
                                                            onChange={(e) =>
                                                                handleColumnChange(
                                                                    index,
                                                                    'bigQueryField',
                                                                    e.target.value,
                                                                )
                                                            }
                                                            fullWidth={true}
                                                        >
                                                            {configuration?.bigQuery?.properties?.map(
                                                                (column, i) =>
                                                                    column.name && (
                                                                        <MenuItem key={i} value={column.name}>
                                                                            {column.name} ({column?.type},{' '}
                                                                            {column?.mode})
                                                                        </MenuItem>
                                                                    ),
                                                            )}
                                                        </CustomSelect>
                                                    ) : (
                                                        <CustomTextField
                                                            status={column.error ? 'error' : null}
                                                            value={column[field] || ''}
                                                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                                                handleColumnChange(index, field, event.target.value)
                                                            }
                                                            fullWidth
                                                            disabled={!column.isEditing}
                                                            error={column.error}
                                                        />
                                                    )}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Grid>
        </>
    );
};

export default ColumnsTable;
