import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFilesApi } from '@/api-client/files-api';
import { useConfigurationsApi } from '@/api-client/configurations-api';
import { useSnackbar } from 'notistack';
import CustomButton from '@/components/custom-button';

const CreateNewConfiguration: React.FC = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openJsonModal, setOpenJsonModal] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const open = Boolean(anchorEl);
    const { uploadFile } = useFilesApi();
    const { createConfigurationFromFile } = useConfigurationsApi();
    const { enqueueSnackbar } = useSnackbar();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleOpenJsonModal = (): void => {
        setOpenJsonModal(true);
    };
    const handleCloseJsonModal = (): void => {
        setOpenJsonModal(false);
        setFile(null);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setFile(event.target.files ? event.target.files[0] : null);
    };

    const handleUploadAndCreateConfiguration = async (): Promise<void> => {
        if (!file) {
            return;
        }

        try {
            let fileUploadResponse;

            try {
                fileUploadResponse = await uploadFile(file, 'configuration');
            } catch (uploadError) {
                if (uploadError?.details?.violations) {
                    uploadError.details.violations.violations.forEach((violation: unknown) => {
                        enqueueSnackbar(`${violation.propertyPath}: ${violation.title}`, {
                            variant: 'error',
                            preventDuplicate: true,
                        });
                    });
                } else {
                    enqueueSnackbar(`File upload failed: ${uploadError?.message || uploadError}`, {
                        variant: 'error',
                        preventDuplicate: true,
                    });
                    console.error('Error uploading file:', uploadError);
                }
                resetFileInput();
                return;
            }

            const { uuid } = fileUploadResponse;

            try {
                const configurationResponse = await createConfigurationFromFile({ fileUuid: uuid });
                navigate(`/configurations/draft/${configurationResponse.id}`);
            } catch (createError) {
                if (createError?.details?.violations) {
                    createError.details.violations.violations.forEach((violation: unknown) => {
                        enqueueSnackbar(`${violation.propertyPath}: ${violation.title}`, {
                            variant: 'error',
                            preventDuplicate: true,
                        });
                    });
                } else {
                    enqueueSnackbar(`Configuration creation failed: ${createError?.message || createError}`, {
                        variant: 'error',
                        preventDuplicate: true,
                    });
                    console.error('Error creating configuration from file:', createError);
                }
                resetFileInput();
            }
        } catch (error) {
            enqueueSnackbar(`Unexpected error: ${error?.message || error}`, {
                variant: 'error',
                preventDuplicate: true,
            });
            console.error('Unexpected error:', error);
            resetFileInput();
        }
    };

    const resetFileInput = (): void => {
        const fileInput = document.getElementById('file-input') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
        // Reset the file state
        setFile(null);
    };

    return (
        <>
            <CustomButton label="+ Create a new configuration" onClick={handleClick} />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => navigate('/configurations/create')}>Create</MenuItem>
                <MenuItem onClick={handleOpenJsonModal}>Create from file</MenuItem>
            </Menu>
            <Dialog open={openJsonModal} onClose={handleCloseJsonModal}>
                <DialogTitle>Create configuration from file</DialogTitle>
                <DialogContent>
                    <input type="file" onChange={handleFileChange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseJsonModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUploadAndCreateConfiguration} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreateNewConfiguration;
