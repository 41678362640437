import React, { useRef, useState, useEffect } from 'react';
import { useFilesApi } from '@/api-client/files-api';
import { useSnackbar } from 'notistack';
import { useConfigurationsApi } from '@/api-client/configurations-api';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store';
import {
    getConfigurationsCurrentConfiguration,
    setCurrentConfigurationTemplateResource,
} from '@/store/configurationsSlice';

interface Props {
    isDraft: boolean;
    configurationId: string | null;
}

const FileUpload: React.FC<Props> = ({ isDraft, configurationId }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadedFileInfo, setUploadedFileInfo] = useState<string | null>(null);
    const [uploadedFileUuid, setUploadedFileUuid] = useState<string | null>(null);
    const { uploadFile } = useFilesApi();
    const {
        postDraftConfigurationTemplate,
        removeDraftConfigurationTemplate,
        postConfigurationTemplate,
        removeConfigurationTemplate,
    } = useConfigurationsApi();
    const fileInput = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams<{ id: string }>();
    const dispatch: AppDispatch = useDispatch();
    const configuration = useSelector(getConfigurationsCurrentConfiguration);

    useEffect(() => {
        if (configuration.templateResource?.templateUuid) {
            setUploadedFileUuid(configuration.templateResource.templateUuid);
            setUploadedFileInfo(`Template file with extension ${configuration.templateResource.templateExtension}`);
        }
    }, [configuration.templateResource]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleUpload = async (): Promise<void> => {
        if (selectedFile) {
            try {
                const response = await uploadFile(selectedFile, 'template');
                setUploadedFileInfo(`Configuration by ${response.ownerId} ${response.createdAt}`);
                setUploadedFileUuid(response.uuid);
                setSelectedFile(null);

                if (isDraft) {
                    await postDraftConfigurationTemplate(configurationId || id, response.uuid);
                } else {
                    await postConfigurationTemplate(configurationId || id, response.uuid);
                }
                dispatch(
                    setCurrentConfigurationTemplateResource({
                        templateExtension: response.extension,
                        templateUuid: response.uuid,
                    }),
                );
                enqueueSnackbar('Template appended successfully', { variant: 'success', preventDuplicate: true });
            } catch (error) {
                enqueueSnackbar(`${error?.title}`, { variant: 'error', preventDuplicate: true });
                console.error('File upload failed', error);
            }
        }
    };

    const handleRemove = async (): Promise<void> => {
        if (uploadedFileUuid) {
            try {
                if (isDraft) {
                    await removeDraftConfigurationTemplate(configurationId || id);
                } else {
                    await removeConfigurationTemplate(configurationId || id);
                }
                setUploadedFileInfo(null);
                setUploadedFileUuid(null);
                dispatch(setCurrentConfigurationTemplateResource({ templateExtension: '', templateUuid: '' }));
                enqueueSnackbar('File removed successfully', { variant: 'success', preventDuplicate: true });
            } catch (error) {
                enqueueSnackbar(`${error?.title}`, { variant: 'error', preventDuplicate: true });
                console.error('File removal failed', error);
            }
        }
    };

    const handleClick = (): void => {
        fileInput.current.click();
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            {uploadedFileInfo && (
                <div style={{ marginBottom: '20px' }}>
                    Currently uploaded file: &quot;{uploadedFileInfo}&quot;
                    <button type="button" onClick={handleRemove} style={{ marginLeft: '10px' }}>
                        Remove
                    </button>
                </div>
            )}
            <input
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="file-input"
                ref={fileInput}
            />
            <label htmlFor="file-input" style={{ marginRight: '10px' }}>
                <button type="button" onClick={handleClick}>
                    Browse...
                </button>
            </label>
            <input
                type="text"
                readOnly
                value={selectedFile ? selectedFile.name : 'Upload the template from your computer...'}
                style={{ width: '300px', marginRight: '10px' }}
            />
            <button type="button" onClick={handleUpload} disabled={!selectedFile} style={{ marginTop: '10px' }}>
                Upload
            </button>
        </div>
    );
};

export default FileUpload;
