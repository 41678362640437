import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './navbar-item.scoped.scss';

interface NavbarItemProps {
    text: string;
    link: string;
}

const NavbarItem: React.FC<NavbarItemProps> = ({ text, link }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = location.pathname === link;

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        <div
            className={`navbar-item ${isActive ? 'active' : ''}`}
            onClick={(e) => {
                e.preventDefault();
                navigate(link);
            }}
        >
            <a href={link}>{text}</a>
        </div>
    );
};

export default NavbarItem;
