import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getUser } from '@/store/usersSlice';
import CustomButton from '@/components/custom-button';

const Home: React.FC = () => {
    const { oktaAuth } = useOktaAuth();
    const user = useSelector((state: RootState) => getUser(state));

    const login = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        oktaAuth.signInWithRedirect();
    };
    return (
        <div style={{ padding: '25px' }}>
            <h1>Home Page</h1>
            {!user.email ? <CustomButton onClick={login} label="Sign In" /> : ''}
        </div>
    );
};

export default Home;
