import { OAuthResponseType } from '@okta/okta-auth-js';
import { getConfig } from '@/configService';

interface OktaConfig {
    clientId: string;
    issuer: string;
    redirectUri: string;
    scopes: string[];
    pkce: boolean;
    responseType: OAuthResponseType[];
}

export const getOktaConfig = async (): Promise<{ oidc: OktaConfig }> => {
    const config = getConfig();

    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || config.JS_REACT_APP_CLIENT_ID;
    const ISSUER = process.env.REACT_APP_ISSUER || config.JS_REACT_APP_ISSUER;
    const REDIRECT_URI = `${window.location.origin}/login/openid-redirect-uri`;

    console.log(process.env.REACT_APP_CLIENT_ID);
    console.log(process.env.REACT_APP_ISSUER);
    console.log(process.env.REACT_APP_BACKEND_URI);

    const oktaConfig: OktaConfig = {
        clientId: CLIENT_ID,
        issuer: ISSUER,
        redirectUri: REDIRECT_URI,
        scopes: ['openid', 'profile', 'email'],
        pkce: false,
        responseType: ['id_token', 'token'],
    };

    return {
        oidc: oktaConfig,
    };
};
