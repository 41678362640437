import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, Users, UsersList } from '@/types';

const initialState: Users = {
    users: {
        user: {
            id: 0,
            email: '',
            firstName: '',
            lastName: '',
            locale: '',
            timeZone: '',
            role: '',
            isActive: true,
            businessTeams: [],
        },
        list: {
            items: [],
            currentPageNumber: 0,
            numItemsPerPage: 0,
            totalCount: 0,
        },
        fullList: {
            items: [],
            currentPageNumber: 0,
            numItemsPerPage: 0,
            totalCount: 0,
        },
        revoked: {
            items: [],
            currentPageNumber: 0,
            numItemsPerPage: 0,
            totalCount: 0,
        },
        admins: {
            items: [],
            currentPageNumber: 0,
            numItemsPerPage: 0,
            totalCount: 0,
        },
        businessUserList: [],
    },
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => {
            state.users.user = action.payload;
        },
        setUsersList: (state, action: PayloadAction<UsersList>) => {
            state.users.list = action.payload;
        },
        setRevokedList: (state, action: PayloadAction<UsersList>) => {
            state.users.revoked = action.payload;
        },
        setAdminsList: (state, action: PayloadAction<UsersList>) => {
            state.users.admins = action.payload;
        },
        setUsersFullList: (state, action: PayloadAction<UsersList>) => {
            state.users.fullList = action.payload;
        },
        setBusinessUserList: (state, action: PayloadAction<Array<unknown>>) => {
            state.users.businessUserList = action.payload;
        },
    },
});

export const { setBusinessUserList, setUser, setUsersList, setRevokedList, setAdminsList, setUsersFullList } =
    usersSlice.actions;

export const getUser = (state: { users: Users }): User => state.users.users.user;
export const getUsersListFromState = (state: { users: Users }): UsersList => state.users.users.list;
export const getRevokedFromState = (state: { users: Users }): UsersList => state.users.users.revoked;
export const getAdminsFromState = (state: { users: Users }): UsersList => state.users.users.admins;
export const getUsersFullListFromState = (state: { users: Users }): UsersList => state.users.users.fullList;
export const getBusinessUserListFromState = (state: { users: Users }): UsersList => state.users.users.businessUserList;

export default usersSlice.reducer;
