import { useApiClient } from '@/api-client/okta-api-client-controller';
import { Configuration } from '@/types';

interface BusinessUserConfigurationsParams {
    keyword?: string;
    categoryId?: number;
    status?: 'Active' | 'Archived';
    page?: number;
    perPage?: number;
    sortBy?: 'id' | 'displayName';
    sortDir?: 'asc' | 'desc';
}

interface PaginatedResponse<T> {
    items: T[];
    currentPageNumber: number;
    numItemsPerPage: number;
    totalCount: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useBusinessUserCategoryApi = () => {
    const { apiClient } = useApiClient();

    const buildQueryString = (params: BusinessUserConfigurationsParams): string => {
        const filteredParams = Object.entries(params)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, value]) => value !== undefined && value !== '')
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        return Object.entries(filteredParams)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
    };

    const getBusinessUserCategoriesList = async (
        params: BusinessUserConfigurationsParams,
    ): Promise<PaginatedResponse<Configuration>> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<PaginatedResponse<Configuration>>(
            `/api/v1/business-user/categories?${queryString}`,
            { method: 'GET' },
            'getBusinessUserCategoriesList',
        );
    };

    return {
        getBusinessUserCategoriesList,
    };
};
