import { useApiClient } from '@/api-client/okta-api-client-controller';
import { PaginatedResponse } from '@/types';

interface UploadHistoryParams {
    status?: 'pending' | 'in_process' | 'error' | 'processing_error' | 'scheduled' | 'done' | 'sending_fail' | 'sent';
    userKeyword?: string;
    userId?: number;
    dateFrom?: string;
    dateTo?: string;
    teamIds?: number[];
    page?: number;
    perPage?: number;
    sortBy?:
        | 'date'
        | 'status'
        | 'version'
        | 'correctionsNumber'
        | 'retriesNumber'
        | 'documentName'
        | 'configurationName';
    sortDir?: 'asc' | 'desc';
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useUploadHistoryApi = () => {
    const { apiClient } = useApiClient();

    const buildQueryString = (params: Record<string, unknown>): string => {
        const filteredParams = Object.entries(params)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, value]) => value !== undefined && value !== '')
            .reduce((acc, [key, value]) => {
                if (Array.isArray(value)) {
                    // Handle arrays without indices
                    return {
                        ...acc,
                        [key]: value.map((v) => encodeURIComponent(v)).join('&' + encodeURIComponent(key) + '='),
                    };
                }
                // Convert key-value pairs to query string format
                return { ...acc, [key]: encodeURIComponent(value.toString()) };
            }, {});

        // Convert filteredParams object into query string format
        return Object.entries(filteredParams)
            .map(([key, value]) => `${encodeURIComponent(key)}=${value}`)
            .join('&');
    };

    const getUploadHistoryListById = async (
        configurationId: number,
        params: UploadHistoryParams,
    ): Promise<PaginatedResponse<UploadHistory>> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<PaginatedResponse<UploadHistory>>(
            `/api/v1/upload-history/configuration/${configurationId}/list?${queryString}`,
            { method: 'GET' },
            'getUploadHistoryList',
        );
    };

    const getUploadHistoryList = async (params: UploadHistoryParams): Promise<PaginatedResponse<UploadHistory>> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<PaginatedResponse<UploadHistory>>(
            `/api/v1/upload-history/list?${queryString}`,
            { method: 'GET' },
            'getUploadHistoryList',
        );
    };

    return {
        getUploadHistoryListById,
        getUploadHistoryList,
    };
};
