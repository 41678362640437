import { makeStyles } from '@mui/styles';
import { FormControl, InputLabel, Select, CircularProgress, InputAdornment, FormHelperText } from '@mui/material';
import React, { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface StyleProps {
    borderColor: string;
    fontColor: string;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        position: 'relative',
        display: 'flex',
    },
    inputLabel: {
        color: '#1A1D20',
        fontSize: '12px',
        transform: 'translate(0, 5px)',
    },
    selectRoot: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
        position: 'relative',
        width: '100%',
        '& .MuiInputAdornment-root': {
            position: 'absolute',
            right: '25px',
            top: '50%',
            transform: 'translateY(-50%)',
            paddingRight: '10px',
            pointerEvents: 'none',
        },
        '&:before, &:after': {
            border: '0!important',
        },
    },
    select: (props: StyleProps): React.CSSProperties => ({
        borderRadius: 0,
        border: '1px solid',
        borderColor: props.borderColor,
        fontSize: 16,
        padding: '10px 40px 10px 8px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        '&:focus, &:hover': {
            borderColor: props.borderColor,
            boxShadow: `${props.borderColor} 0 0 0 1px`,
            backgroundColor: '#fff',
        },
    }),
    helperText: (props: StyleProps): React.CSSProperties => ({
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '16px',
        color: props.fontColor,
    }),
    list: (props: StyleProps): React.CSSProperties => ({
        backgroundColor: '#fff',
        borderRadius: 0,
        border: '1px solid',
        borderColor: props.borderColor,
        padding: 0,
        '& .MuiList-root': {
            padding: 0,
        },
        '& .MuiMenuItem-root': {
            fontSize: '14px',
            '&:hover': {
                backgroundColor: '#f5f5f5',
            },
            '&.Mui-selected': {
                backgroundColor: '#e0e0e0',
                '&:hover': {
                    backgroundColor: '#d5d5d5',
                },
            },
        },
    }),
    icon: {
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
    },
    iconOpen: {
        transform: 'translateY(-50%) rotate(180deg)',
    },
}));

interface CustomSelectProps {
    label?: string;
    status?: 'loading' | 'success' | 'error' | null;
    helperText?: string;
    required?: boolean;
    value: string;
    onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    name?: string;
    disabled?: boolean;
    multiple?: boolean;
    children: React.ReactNode;
    renderValue?: (value: unknown) => React.ReactNode;
    fullWidth?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
    label,
    status,
    helperText,
    required,
    value,
    onChange,
    name,
    disabled,
    multiple = false,
    children,
    renderValue,
    fullWidth = false,
}) => {
    const [open, setOpen] = useState(false);

    const borderColor: string =
        status === 'loading' ? 'blue' : status === 'success' ? 'green' : status === 'error' ? '#D62623' : '#54616D';
    const fontColor: string = status === 'error' ? '#D62623' : '#54616D';
    const classes = useStyles({ borderColor, fontColor });

    let endAdornment: React.ReactNode;
    switch (status) {
        case 'loading':
            endAdornment = <CircularProgress size={20} />;
            break;
        case 'success':
            endAdornment = <CheckCircleIcon style={{ color: 'green' }} />;
            break;
        case 'error':
            endAdornment = <HighlightOffIcon style={{ color: 'red' }} />;
            break;
        default:
            endAdornment = null;
    }

    const ArrowIcon = ({ open }: { open: boolean }): JSX.Element => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className={`${classes.icon} ${open ? classes.iconOpen : ''}`}
            >
                <path
                    d="M11.9999 16.0607L5.46961 9.53034L6.53028 8.46968L11.9999 13.9393L17.4696 8.46968L18.5303 9.53034L11.9999 16.0607Z"
                    fill="#030F2B"
                />
            </svg>
        );
    };

    return (
        <FormControl variant="standard" className={classes.formControl} fullWidth={fullWidth}>
            {label && (
                <InputLabel className={classes.inputLabel} shrink htmlFor="custom-select">
                    {label}
                    {required && ' *'}
                </InputLabel>
            )}
            <Select
                id="custom-select"
                classes={{ root: classes.selectRoot, select: classes.select }}
                value={value}
                onChange={onChange}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                name={name}
                disabled={disabled}
                multiple={multiple}
                displayEmpty
                renderValue={renderValue}
                MenuProps={{
                    classes: { paper: classes.list },
                }}
                endAdornment={endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>}
                IconComponent={() => <ArrowIcon open={open} />}
            >
                {children}
            </Select>
            {helperText && <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default CustomSelect;
