import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BusinessTeams, BusinessTeamsList } from '@/types';

const initialState: BusinessTeams = {
    businessTeams: {
        list: {
            items: [],
            currentPageNumber: 0,
            numItemsPerPage: 0,
            totalCount: 0,
        },
        fullList: {
            items: [],
            currentPageNumber: 0,
            numItemsPerPage: 0,
            totalCount: 0,
        },
    },
};

const businessTeamsSlice = createSlice({
    name: 'businessTeams',
    initialState,
    reducers: {
        setBusinessTeamsList: (state, action: PayloadAction<BusinessTeamsList>) => {
            state.businessTeams.list = action.payload;
        },
        setBusinessTeamsFullList: (state, action: PayloadAction<BusinessTeamsList>) => {
            state.businessTeams.fullList = action.payload;
        },
    },
});

export const { setBusinessTeamsList, setBusinessTeamsFullList } = businessTeamsSlice.actions;

export const getBusinessTeamsListFromState = (state: { businessTeams: BusinessTeams }): BusinessTeamsList =>
    state.businessTeams.businessTeams.list;
export const getBusinessTeamsFullListFromState = (state: { businessTeams: BusinessTeams }): BusinessTeamsList =>
    state.businessTeams.businessTeams.fullList;

export default businessTeamsSlice.reducer;
