import { useApiClient } from '@/api-client/okta-api-client-controller';
import { Configuration, ConfigurationsList, User } from '@/types';

interface ConfigurationListParams {
    keyword?: string;
    status?: string;
    isActive?: boolean;
    sortBy?: string;
    sortDir?: string;
    page?: number;
    perPage?: number;
    categoryId?: string;
}

interface ConfigurationCategoryListParams {
    keyword?: string;
    status?: string;
    sortBy?: string;
    sortDir?: string;
    page?: number;
    perPage?: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useConfigurationsApi = () => {
    const { apiClient } = useApiClient();

    const buildQueryString = (params: ConfigurationListParams): string => {
        const filteredParams: ConfigurationListParams = Object.entries(params)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, value]) => value !== undefined && value !== '')
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        const queryString = Object.entries(filteredParams)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');

        return queryString;
    };

    const createConfiguration = async (data: Partial<Configuration>): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            '/api/v1/configuration/draft/create',
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'createConfiguration',
        );
    };

    const createConfigurationFromFile = async (fileUuid: string): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            '/api/v1/configuration/draft/import',
            {
                method: 'POST',
                body: JSON.stringify(fileUuid),
                headers: { 'Content-Type': 'application/json' },
            },
            'createConfigurationFromFile',
        );
    };

    const createConfigurationCategory = async (data: Partial<Configuration>): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            '/api/v1/category/create',
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'createConfigurationCategory',
        );
    };

    const getConfigurationsList = async (params: ConfigurationListParams): Promise<ConfigurationsList> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<ConfigurationsList>(
            `/api/v1/configurations?${queryString}`,
            { method: 'GET' },
            'getConfigurationsList',
        );
    };

    const getConfigurationsDraftList = async (params: ConfigurationListParams): Promise<ConfigurationsList> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<ConfigurationsList>(
            `/api/v1/configurations/draft?${queryString}`,
            { method: 'GET' },
            'getConfigurationsDraftList',
        );
    };

    const getConfigurationsCategoriesList = async (
        params: ConfigurationCategoryListParams,
    ): Promise<ConfigurationCategoryListParams> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<ConfigurationCategoryListParams>(
            `/api/v1/categories?${queryString}`,
            { method: 'GET' },
            'getConfigurationsCategoriesList',
        );
    };

    const getConfigurationVersionList = async (
        params: ConfigurationCategoryListParams,
        configurationId: number,
    ): Promise<ConfigurationCategoryListParams> => {
        console.log(params);
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<ConfigurationCategoryListParams>(
            `/api/v1/configuration/${configurationId}/versions?${queryString}`,
            { method: 'GET' },
            'getConfigurationVersionList',
        );
    };

    const restoreConfiguration = async (configurationId: number): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/business-team/${configurationId}/restore`,
            { method: 'POST' },
            'restoreConfiguration',
        );
    };

    const postDraftConfigurationActivate = async (configurationId: number): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/draft/${configurationId}/activate`,
            { method: 'POST' },
            'postDraftConfigurationActivate',
        );
    };

    const postConfigurationVersionActivate = async (versionId: number): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/version/${versionId}/activate`,
            { method: 'POST' },
            'postConfigurationVersionActivate',
        );
    };

    const restoreConfigurationCategory = async (
        configurationId: number,
        withConfigurations: boolean,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/category/${configurationId}/restore?withConfigurations=${withConfigurations}`,
            { method: 'POST' },
            'restoreConfigurationCategory',
        );
    };

    const revokeConfiguration = async (configurationId: number): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/business-team/${configurationId}/revoke`,
            { method: 'POST' },
            'revokeConfiguration',
        );
    };

    const archiveConfigurationCategory = async (configurationId: number): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/category/${configurationId}/archive`,
            { method: 'POST' },
            'archiveConfigurationCategory',
        );
    };

    const updateConfiguration = async (
        configurationId: number,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/${configurationId}/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'updateConfiguration',
        );
    };

    const updateConfigurationCategory = async (
        configurationId: number,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/category/${configurationId}/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'updateConfigurationCategory',
        );
    };

    const updateDraftConfiguration = async (
        configurationId: string,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/draft/${configurationId}/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'updateDraftConfiguration',
        );
    };

    const updateDraftConfigurationDetails = async (
        configurationId: string,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/draft/${configurationId}/details/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'updateDraftConfigurationDetails',
        );
    };

    const updateConfigurationPermissions = async (
        configurationId: string,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/${configurationId}/permissions/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'updateDraftConfigurationRules',
        );
    };

    const updateDraftConfigurationPermissions = async (
        configurationId: string,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/draft/${configurationId}/permissions/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'updateDraftConfigurationRules',
        );
    };

    const updateConfigurationRules = async (
        configurationId: string,
        ruleId: string,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/${configurationId}/schema-rule/${ruleId}/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'updateConfigurationRules',
        );
    };

    const updateDraftConfigurationRules = async (
        configurationId: string,
        ruleId: string,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/draft/${configurationId}/schema-rule/${ruleId}/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'updateDraftConfigurationRules',
        );
    };

    const postConfigurationRules = async (
        configurationId: string,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/${configurationId}/schema-rule/create`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'postConfigurationRules',
        );
    };

    const postDraftConfigurationRules = async (
        configurationId: string,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/draft/${configurationId}/schema-rule/create`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'postDraftConfigurationRules',
        );
    };

    const removeConfigurationRules = async (configurationId: string, ruleId: string): Promise<void> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<void>(
            `/api/v1/configuration/${configurationId}/schema-rule/${ruleId}/remove`,
            {
                method: 'DELETE',
            },
            'removeConfigurationRules',
        );
    };

    const removeDraftConfigurationRules = async (configurationId: string, ruleId: string): Promise<void> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<void>(
            `/api/v1/configuration/draft/${configurationId}/schema-rule/${ruleId}/remove`,
            {
                method: 'DELETE',
            },
            'removeDraftConfigurationRules',
        );
    };

    const updateConfigurationColumns = async (
        configurationId: string,
        columnId: string,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/${configurationId}/schema-column/${columnId}/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'updateConfigurationColumns',
        );
    };

    const updateDraftConfigurationColumns = async (
        configurationId: string,
        columnId: string,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/draft/${configurationId}/schema-column/${columnId}/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'updateDraftConfigurationColumns',
        );
    };

    const updateDraftConfigurationFileOptions = async (
        configurationId: string,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/draft/${configurationId}/file-options/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'updateDraftConfigurationFileOptions',
        );
    };

    const updateConfigurationFileOptions = async (
        configurationId: string,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/${configurationId}/file-options/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'updateConfigurationFileOptions',
        );
    };

    const postConfigurationColumns = async (
        configurationId: string,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/${configurationId}/schema-column/create`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'postConfigurationColumns',
        );
    };

    const postDraftConfigurationColumns = async (
        configurationId: string,
        data: Partial<Configuration>,
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/draft/${configurationId}/schema-column/create`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'postDraftConfigurationColumns',
        );
    };

    const removeDraftConfigurationColumns = async (configurationId: string, columnId: string): Promise<void> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<void>(
            `/api/v1/configuration/draft/${configurationId}/schema-column/${columnId}/remove`,
            {
                method: 'DELETE',
            },
            'removeDraftConfigurationColumns',
        );
    };

    const removeConfigurationColumns = async (configurationId: string, columnId: string): Promise<void> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<void>(
            `/api/v1/configuration/${configurationId}/schema-column/${columnId}/remove`,
            {
                method: 'DELETE',
            },
            'removeDraftConfigurationColumns',
        );
    };

    const getConfigurationById = async (itemId: string): Promise<User> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<User>(
            `/api/v1/configuration/${itemId}`,
            { method: 'GET' },
            `getConfigurationById-${itemId}`,
        );
    };

    const getConfigurationEditableById = async (itemId: string): Promise<User> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<User>(
            `/api/v1/configuration/${itemId}/editable`,
            { method: 'GET' },
            `getConfigurationEditableById-${itemId}`,
        );
    };

    const getConfigurationDraftById = async (itemId: string): Promise<User> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<User>(
            `/api/v1/configuration/draft/${itemId}`,
            { method: 'GET' },
            `getConfigurationDraftById-${itemId}`,
        );
    };

    const postDraftConfigurationTemplate = async (configurationId: string, uuid: string): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/draft/${configurationId}/template-append/${uuid}`,
            {
                method: 'POST',
            },
            'postDraftConfigurationTemplate',
        );
    };

    const removeDraftConfigurationTemplate = async (configurationId: string): Promise<void> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<void>(
            `/api/v1/configuration/draft/${configurationId}/template-remove`,
            {
                method: 'DELETE',
            },
            'removeDraftConfigurationTemplate',
        );
    };

    const postConfigurationTemplate = async (configurationId: string, uuid: string): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/${configurationId}/template-append/${uuid}`,
            {
                method: 'POST',
            },
            'postConfigurationTemplate',
        );
    };

    const removeConfigurationTemplate = async (configurationId: string): Promise<void> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<void>(
            `/api/v1/configuration/${configurationId}/template-remove`,
            {
                method: 'DELETE',
            },
            'removeConfigurationTemplate',
        );
    };

    const putDraftConfigurationSendingOptionsUpdate = async (
        draftConfigurationId: number,
        data: {
            type: 'automatic' | 'manual' | 'schedule';
            schedule?: {
                periodicity: 'daily' | 'weekly' | 'bi-weekly' | 'monthly';
                hour: number;
                minute: number;
                dayWeek?: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday' | null;
                dayMonth?: number | null;
            } | null;
        },
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/draft/${draftConfigurationId}/sending-options/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'putDraftConfigurationSendingOptionsUpdate',
        );
    };

    const putConfigurationSendingOptionsUpdate = async (
        configurationId: number,
        data: {
            type: 'automatic' | 'manual' | 'schedule';
            schedule?: {
                periodicity: 'daily' | 'weekly' | 'bi-weekly' | 'monthly';
                hour: number;
                minute: number;
                dayWeek?: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday' | null;
                dayMonth?: number | null;
            } | null;
        },
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/${configurationId}/sending-options/update`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'putConfigurationSendingOptionsUpdate',
        );
    };

    const postDraftConfigurationBigQueryMappingSet = async (
        draftConfiguration: string,
        data: {
            dataset: string;
            table: string;
            properties: Array<object>;
        },
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/draft/${draftConfiguration}/big-query-mapping`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'postDraftConfigurationBigQueryMappingSet',
        );
    };

    const postConfigurationBigQueryMappingSet = async (
        configuration: string,
        data: {
            dataset: string;
            table: string;
            properties: Array<object>;
        },
    ): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/configuration/${configuration}/big-query-mapping`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            },
            'postConfigurationBigQueryMappingSet',
        );
    };

    const postConfigurationRestore = async (configurationId: number): Promise<void> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<void>(
            `/api/v1/configuration/${configurationId}/restore`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            },
            'postConfigurationRestore',
        );
    };

    const postConfigurationArchive = async (configurationId: number): Promise<void> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<void>(
            `/api/v1/configuration/${configurationId}/archive`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            },
            'postConfigurationArchive',
        );
    };

    const removeDraftConfigurationRemove = async (draftConfigurationId: number): Promise<void> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<void>(
            `/api/v1/configuration/draft/${draftConfigurationId}/remove`,
            {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            },
            'removeDraftConfigurationRemove',
        );
    };

    return {
        archiveConfigurationCategory,
        createConfiguration,
        createConfigurationCategory,
        createConfigurationFromFile,
        getConfigurationById,
        getConfigurationDraftById,
        getConfigurationEditableById,
        getConfigurationVersionList,
        getConfigurationsCategoriesList,
        getConfigurationsDraftList,
        getConfigurationsList,
        postConfigurationRestore,
        postConfigurationArchive,
        postConfigurationColumns,
        postConfigurationRules,
        postConfigurationTemplate,
        postConfigurationVersionActivate,
        postDraftConfigurationActivate,
        postDraftConfigurationColumns,
        postDraftConfigurationRules,
        postDraftConfigurationTemplate,
        postDraftConfigurationBigQueryMappingSet,
        postConfigurationBigQueryMappingSet,
        removeConfigurationColumns,
        removeConfigurationRules,
        removeConfigurationTemplate,
        removeDraftConfigurationColumns,
        removeDraftConfigurationRules,
        removeDraftConfigurationTemplate,
        removeDraftConfigurationRemove,
        restoreConfiguration,
        restoreConfigurationCategory,
        revokeConfiguration,
        updateConfiguration,
        updateConfigurationCategory,
        updateConfigurationColumns,
        updateConfigurationFileOptions,
        updateConfigurationPermissions,
        updateConfigurationRules,
        updateDraftConfiguration,
        updateDraftConfigurationColumns,
        updateDraftConfigurationDetails,
        updateDraftConfigurationFileOptions,
        updateDraftConfigurationPermissions,
        updateDraftConfigurationRules,
        putDraftConfigurationSendingOptionsUpdate,
        putConfigurationSendingOptionsUpdate,
    };
};
