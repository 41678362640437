import { useApiClient } from '@/api-client/okta-api-client-controller';
import { ActivityLogsList, ActivityLogsRequirements } from '@/types';

interface ActivityLogsListParams {
    entityName?: string | null;
    activityName?: string | null;
    dateStart?: string | null;
    dateEnd?: string | null;
    entityId?: number | null;
    userId?: number | null;
    page?: number | null;
    perPage?: number | null;
    sortBy?: 'createdAt' | 'id';
    sortDir?: 'asc' | 'desc';
}

interface ActivityLogsExportParams extends ActivityLogsListParams {}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useActivityLogsApi = () => {
    const { apiClient } = useApiClient();

    const buildQueryString = (params: Record<string, unknown>): string => {
        const filteredParams = Object.entries(params)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, value]) => value !== undefined && value !== null && value !== '')
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        const queryString = Object.entries(filteredParams)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');

        return queryString;
    };

    const getActivityLogsList = async (params: ActivityLogsListParams): Promise<ActivityLogsList> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<ActivityLogsList>(
            `/api/v1/activity-logs/list?${queryString}`,
            { method: 'GET' },
            'getActivityLogsList',
        );
    };

    const getActivityLogsConfigurationLogs = async (
        configuration: number,
        page?: number,
        perPage?: number,
    ): Promise<ActivityLogsList> => {
        const queryString = buildQueryString({ page, perPage });
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<ActivityLogsList>(
            `/api/v1/activity-logs/configuration/${configuration}/logs?${queryString}`,
            { method: 'GET' },
            'getActivityLogsConfigurationLogs',
        );
    };

    const getActivityLogsRequirements = async (): Promise<ActivityLogsRequirements> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<ActivityLogsRequirements>(
            '/api/v1/activity-logs/requirements',
            { method: 'GET' },
            'getActivityLogsRequirements',
        );
    };

    const exportActivityLogs = async (params: ActivityLogsExportParams): Promise<void> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }

        try {
            const response = await apiClient.fetch(
                `/api/v1/activity-logs/export?${queryString}`,
                { method: 'GET' },
                'exportActivityLogs',
            );

            // @ts-expect-error @ts-expect-error
            const blob = new Blob([response], { type: 'text/csv' });

            // eslint-disable-next-line compat/compat
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'activity_logs.csv';
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            // eslint-disable-next-line compat/compat
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error exporting activity logs:', error);
            throw error;
        }
    };

    const getActivityLogsMappings = async (): Promise<Record<string, string[]>> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Record<string, string[]>>(
            '/api/v1/activity-logs/mappings',
            { method: 'GET' },
            'getActivityLogsMappings',
        );
    };

    return {
        getActivityLogsList,
        getActivityLogsConfigurationLogs,
        getActivityLogsRequirements,
        exportActivityLogs,
        getActivityLogsMappings,
    };
};
