import { OktaAuth } from '@okta/okta-auth-js';

export class ApiClient {
    private oktaAuth: OktaAuth;
    private baseUrl: string;
    private controllers: Map<string, AbortController>;

    constructor(oktaAuth: OktaAuth, baseUrl: string) {
        this.oktaAuth = oktaAuth;
        this.baseUrl = baseUrl;
        this.controllers = new Map();
    }

    private async getAccessToken(): Promise<string> {
        const accessToken = await this.oktaAuth.getAccessToken();

        if (!accessToken) {
            throw new Error('Failed to get access token from Okta');
        }
        return accessToken;
    }

    public async fetch<T>(endpoint: string, options: RequestInit = {}, requestId: string | null = null): Promise<T> {
        if (!options.headers) {
            options.headers = {};
        }

        const accessToken = await this.getAccessToken();

        const headers: Record<string, string> =
            options.headers instanceof Headers
                ? Object.fromEntries(options.headers.entries())
                : (options.headers as Record<string, string>);

        headers['Authorization'] = `Bearer ${accessToken}`;

        options.headers = headers;

        const url = `${this.baseUrl}${endpoint}`;
        const controller = new AbortController();
        options.signal = controller.signal;

        if (requestId) {
            this.controllers.set(requestId, controller);
        }

        try {
            // eslint-disable-next-line compat/compat
            const response = await fetch(url, options);

            if (!response.ok) {
                const errorText = await response.text();
                // try {
                const errorResponse = JSON.parse(errorText);
                throw errorResponse;
                // } catch (e) {
                //     throw new Error(errorText);
                // }
            }

            const responseText = await response.text();
            try {
                return JSON.parse(responseText) as T;
            } catch (e) {
                return responseText as unknown as T;
            }
        } finally {
            if (requestId) {
                this.controllers.delete(requestId);
            }
        }
    }

    public abort(requestId: string): void {
        const controller = this.controllers.get(requestId);
        if (controller) {
            controller.abort();
            this.controllers.delete(requestId);
        }
    }
}

export default ApiClient;
