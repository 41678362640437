import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LocaleState {
    value: string;
}

const initialState: LocaleState = {
    value: localStorage.getItem('locale') || 'en',
};

const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setLocale: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
            localStorage.setItem('locale', action.payload);
        },
    },
});

export const { setLocale } = localeSlice.actions;

export default localeSlice.reducer;
