import { useApiClient } from '@/api-client/okta-api-client-controller';

interface RuleValidateParams {
    value: string;
    expression: string;
    ruleId: number;
    type: 'draft' | 'published';
}

interface RuleValidateResponse {
    error?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCloudFunctionsApi = () => {
    const { apiClient } = useApiClient();

    const postCloudFunctionRuleValidate = async (params: RuleValidateParams): Promise<RuleValidateResponse> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<RuleValidateResponse>(
            '/api/v1/cloud-functions/rule-validate',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(params),
            },
            'postCloudFunctionRuleValidate',
        );
    };

    return {
        postCloudFunctionRuleValidate,
    };
};
