import { useApiClient } from '@/api-client/okta-api-client-controller';

interface BigQueryDatasetParams {
    isActive?: boolean;
    page?: number;
    perPage?: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useBigQueryApi = () => {
    const { apiClient } = useApiClient();

    const buildQueryString = (params: BigQueryDatasetParams): string => {
        const filteredParams: BigQueryDatasetParams = Object.entries(params)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, value]) => value !== undefined && value !== '')
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        const queryString = Object.entries(filteredParams)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');

        return queryString;
    };

    const getBigQueryDatasets = async (params: BigQueryDatasetParams): Promise<string[]> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<string[]>(
            `/api/v1/big-query/datasets?${queryString}`,
            { method: 'GET' },
            'getBigQueryDatasets',
        );
    };

    const getBigQueryDatasetTables = async (datasetId: string): Promise<string[]> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<string[]>(
            `/api/v1/big-query/datasets/${datasetId}/tables`,
            { method: 'GET' },
            'getBigQueryDatasetTables',
        );
    };

    const getBigQueryDatasetTableProperties = async (
        datasetId: string,
        tableId: string,
    ): Promise<{ name: string; type: string; mode: string }[]> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<{ name: string; type: string; mode: string }[]>(
            `/api/v1/big-query/datasets/${datasetId}/tables/${tableId}/properties`,
            { method: 'GET' },
            'getBigQueryDatasetTableProperties',
        );
    };

    return {
        getBigQueryDatasets,
        getBigQueryDatasetTables,
        getBigQueryDatasetTableProperties,
    };
};
