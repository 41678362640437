import React, { useEffect, useState } from 'react';
import {
    Paper,
    MenuItem,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUsersApi } from '@/api-client/users-api';
import { useLocation, useParams } from 'react-router-dom';
import { AVAILABLE_ROLES, BusinessTeam } from '@/types';
import { getBusinessTeamsFullListFromState } from '@/store/businessTeamsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import { getUser, setUsersFullList } from '@/store/usersSlice';
import { useSnackbar } from 'notistack';
import CustomButton from '@/components/custom-button';
import CustomTextField from '@/components/custom-text-field';
import CustomSelect from '@/components/custom-select';

const validationSchema = yup.object().shape({
    firstName: yup
        .string()
        .required('Field must not be blank')
        .matches(
            /^[A-Za-zÀ-ÖØ-öø-ÿ\- ]{2,50}$/,
            'Name must contain from 2 to 50 characters and consist of letters, spaces, hyphens, or accents only',
        ),
    lastName: yup
        .string()
        .required('Field must not be blank')
        .matches(
            /^[A-Za-zÀ-ÖØ-öø-ÿ\- ]{2,50}$/,
            'Lastname must contain from 2 to 50 characters and consist of letters, spaces, hyphens, or accents only',
        ),
    email: yup
        .string()
        .required('Field must not be blank')
        .email(
            'An invalid email address was entered. Please make sure you enter a valid email address in the format name@example.com',
        ),
    role: yup.string().required('Field must not be blank'),
});

const Edit: React.FC = () => {
    const { id } = useParams();
    const { state } = useLocation();
    const businessTeamsList = useSelector(getBusinessTeamsFullListFromState);
    const user = useSelector((state: RootState) => getUser(state));
    const { getUserById, revokeUser, createUser, updateUser, getUsersList } = useUsersApi();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch: AppDispatch = useDispatch();

    const [openRevoke, setOpenRevoke] = useState(false);
    const [openRoleChange, setOpenRoleChange] = useState(false);
    const [pendingRole, setPendingRole] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [loading, setLoading] = useState(false);

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (id) {
            setLoading(true);
            fetchUser();
        }
    }, [id]);

    useEffect(() => {
        if (state) {
            const user = state;
            reset({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                role: user.role,
                businessTeam: user.businessTeams.map((team) => team.id),
            });
        }
    }, [state]);

    const fetchUser = async (): Promise<void> => {
        try {
            setLoading(true);
            const user = await getUserById(id);
            reset({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                role: user.role,
                businessTeam: user.businessTeams.map((team) => team.id),
            });
            setSelectedRole(user.role);
        } catch (error) {
            console.error('Error fetching user list:', error);
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async (data: unknown): Promise<void> => {
        try {
            const transformedData = {
                ...data,
                businessTeamIds: data.businessTeam || [],
            };
            delete transformedData.businessTeam;

            if (id) {
                await updateUser(id, transformedData);
                enqueueSnackbar(`User updated successfully`, {
                    variant: 'success',
                    preventDuplicate: true,
                });
            } else {
                await createUser(transformedData);
                enqueueSnackbar(`User created successfully`, {
                    variant: 'success',
                    preventDuplicate: true,
                });
            }
        } catch (error) {
            if (error.type === 'already_exist') {
                enqueueSnackbar(`User with this email address already exists`, {
                    variant: 'error',
                    preventDuplicate: true,
                });
            } else if (error.type === 'validation_error') {
                error.details.violations.violations.forEach((violation: unknown) => {
                    enqueueSnackbar(`${violation.propertyPath}: ${violation.title}`, {
                        variant: 'error',
                        preventDuplicate: true,
                    });
                });
            } else {
                enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            }
        }
    };

    const fetchUsersList = async (): Promise<void> => {
        try {
            const list = await getUsersList({ perPage: 1000 });
            dispatch(setUsersFullList(list));
        } catch (error) {
            enqueueSnackbar(`${error?.title}`, { variant: 'error', preventDuplicate: true });
            console.error('Error fetching business teams list:', error);
        }
    };

    const fetchRevokeUser = async (userId: string): Promise<void> => {
        try {
            await revokeUser(userId);
            handleCloseRevoke();
            enqueueSnackbar(`User revoked successfully`, {
                variant: 'success',
                preventDuplicate: true,
            });
            fetchUsersList();
        } catch (error) {
            enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            console.error('Error revoking user:', error);
        }
    };

    const handleClickOpenRevoke = (): void => {
        setOpenRevoke(true);
    };

    const handleCloseRevoke = (): void => {
        setOpenRevoke(false);
    };

    const handleClickOpenRoleChange = (selectedRole: string): void => {
        setPendingRole(selectedRole);
        setOpenRoleChange(true);
    };

    const handleCloseRoleChange = (): void => {
        setOpenRoleChange(false);
        setPendingRole('');
    };

    const handleConfirmRoleChange = (): void => {
        setValue('role', pendingRole);
        setOpenRoleChange(false);
    };

    const handleRoleChange = (selectedRole: string): void => {
        setSelectedRole(selectedRole);
        if (selectedRole === AVAILABLE_ROLES.ROLE_ADMIN || selectedRole === AVAILABLE_ROLES.ROLE_PLATFORM_ADMIN) {
            setValue('businessTeam', []);
        }
        if (
            (id && selectedRole === AVAILABLE_ROLES.ROLE_PLATFORM_ADMIN) ||
            (id && selectedRole === AVAILABLE_ROLES.ROLE_ADMIN)
        ) {
            handleClickOpenRoleChange(selectedRole);
        } else {
            setValue('role', selectedRole);
        }
    };

    return (
        <div style={{ padding: '25px' }}>
            <Paper style={{ padding: '25px' }}>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '25px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="firstName"
                            control={control}
                            render={({ field }) => (
                                <CustomTextField
                                    {...field}
                                    label="First Name"
                                    fullWidth
                                    status={errors.firstName ? 'error' : null}
                                    helperText={errors.firstName?.message}
                                    value={field.value || ''}
                                />
                            )}
                        />
                        <Controller
                            name="lastName"
                            control={control}
                            render={({ field }) => (
                                <CustomTextField
                                    {...field}
                                    label="Last Name"
                                    fullWidth
                                    status={errors.lastName ? 'error' : null}
                                    helperText={errors.lastName?.message}
                                    value={field.value || ''}
                                />
                            )}
                        />
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <CustomTextField
                                    {...field}
                                    label="Email"
                                    fullWidth
                                    status={errors.email ? 'error' : null}
                                    helperText={errors.email?.message}
                                    value={field.value || ''}
                                />
                            )}
                        />
                        <FormControl fullWidth error={!!errors.role}>
                            <Controller
                                name="role"
                                control={control}
                                render={({ field }) => (
                                    <CustomSelect
                                        label="Role"
                                        {...field}
                                        value={field.value || ''}
                                        onChange={(e) => handleRoleChange(e.target.value as string)}
                                        status={errors.role ? 'error' : null}
                                        helperText={errors.role?.message}
                                    >
                                        {user.role === AVAILABLE_ROLES.ROLE_PLATFORM_ADMIN && (
                                            <MenuItem value={AVAILABLE_ROLES.ROLE_PLATFORM_ADMIN}>
                                                Platform Admin
                                            </MenuItem>
                                        )}
                                        <MenuItem value={AVAILABLE_ROLES.ROLE_ADMIN}>Admin</MenuItem>
                                        <MenuItem value={AVAILABLE_ROLES.ROLE_USER}>User</MenuItem>
                                    </CustomSelect>
                                )}
                            />
                        </FormControl>
                        {!(
                            selectedRole === AVAILABLE_ROLES.ROLE_ADMIN ||
                            selectedRole === AVAILABLE_ROLES.ROLE_PLATFORM_ADMIN
                        ) && (
                            <FormControl fullWidth>
                                <Controller
                                    name="businessTeam"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomSelect
                                            label="Business Team"
                                            {...field}
                                            multiple
                                            value={field.value || []}
                                            onChange={(e) => setValue('businessTeam', e.target.value as number[])}
                                            disabled={
                                                selectedRole === AVAILABLE_ROLES.ROLE_ADMIN ||
                                                selectedRole === AVAILABLE_ROLES.ROLE_PLATFORM_ADMIN
                                            }
                                            status={errors.businessTeam ? 'businessTeam' : null}
                                        >
                                            <MenuItem value="none" disabled>
                                                None
                                            </MenuItem>
                                            {businessTeamsList.items
                                                .filter((team) => team.isActive)
                                                .map((team: BusinessTeam) => (
                                                    <MenuItem key={team.id} value={team.id}>
                                                        {team.name}
                                                    </MenuItem>
                                                ))}
                                        </CustomSelect>
                                    )}
                                />
                            </FormControl>
                        )}
                        {id ? (
                            <CustomButton label="Save" type="submit" />
                        ) : (
                            <CustomButton label="Submit" type="submit" />
                        )}
                        {id && (
                            <>
                                {user.role === AVAILABLE_ROLES.ROLE_PLATFORM_ADMIN ||
                                user.role === AVAILABLE_ROLES.ROLE_ADMIN ? (
                                    <CustomButton label="Revoke User" onClick={handleClickOpenRevoke} />
                                ) : (
                                    ''
                                )}
                                <Dialog open={openRevoke} onClose={handleCloseRevoke}>
                                    <DialogTitle>Confirm Revoke</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>Do you really want to revoke this user?</DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseRevoke} color="primary">
                                            No
                                        </Button>
                                        <Button onClick={() => fetchRevokeUser(id)} color="primary">
                                            Yes
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </>
                        )}
                        <Dialog open={openRoleChange} onClose={handleCloseRoleChange}>
                            <DialogTitle>Confirm Role Change</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Do you really want to grant the{' '}
                                    {pendingRole === AVAILABLE_ROLES.ROLE_PLATFORM_ADMIN ? 'Platform Admin' : 'Admin'}{' '}
                                    access to {user.firstName} {user.lastName}?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseRoleChange} color="primary">
                                    No
                                </Button>
                                <Button onClick={handleConfirmRoleChange} color="primary">
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </form>
                )}
            </Paper>
        </div>
    );
};

export default Edit;
