import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import fr from './fr.json';
import { RootState, store } from '@/store';

const resources = {
    en: {
        translation: en,
    },
    fr: {
        translation: fr,
    },
};

export const initializeI18n = (defaultLanguage: string): void => {
    i18n.use(initReactI18next).init({
        lng: defaultLanguage,
        resources,
    });
};

const updateI18nLanguage = (language: string): void => {
    i18n.changeLanguage(language);
};

export const getDefaultLanguage = (state: RootState): string => {
    return state.locale.value;
};

store.subscribe(() => {
    const newLanguage = getDefaultLanguage(store.getState());
    updateI18nLanguage(newLanguage);
});
