import { combineReducers } from 'redux';

import counterReducer from '@/store/counterSlice';
import localeReducer from '@/store/localeSlice';
import usersReducer from '@/store/usersSlice';
import businessTeamsReducer from '@/store/businessTeamsSlice';
import configurationsReducer from '@/store/configurationsSlice';

const rootReducer = combineReducers({
    counter: counterReducer,
    locale: localeReducer,
    users: usersReducer,
    businessTeams: businessTeamsReducer,
    configurations: configurationsReducer,
});

export default rootReducer;
