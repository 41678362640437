import React, { useCallback } from 'react';
import { Autocomplete, Grid, InputLabel, SelectChangeEvent } from '@mui/material';
import { useConfigurationsApi } from '@/api-client/configurations-api';
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessTeamsFullListFromState } from '@/store/businessTeamsSlice';
import { AppDispatch, RootState } from '@/store';
import { getUsersFullListFromState } from '@/store/usersSlice';
import {
    getConfigurationsCurrentConfiguration,
    setCurrentConfigurationTeams,
    setCurrentConfigurationUsers,
} from '@/store/configurationsSlice';
import { useParams } from 'react-router-dom';
import { BusinessTeam, User } from '@/types';
import debounce from 'lodash/debounce';
import CustomTextField from '@/components/custom-text-field';
import { makeStyles } from '@mui/styles';

interface Props {
    isDraft: boolean;
    configurationId: string | null;
}

const useStyles = makeStyles(() => ({
    popper: {
        border: '1px solid #54616D',
    },
    paper: {
        boxShadow: 'none',
        borderRadius: 0,
    },
    listbox: {
        padding: 0,
    },
    option: {
        '&[aria-selected="true"]': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        '&[data-focus="true"]': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
        },
    },
}));

const PermissionsTab: React.FC<Props> = ({ isDraft, configurationId }) => {
    const { updateDraftConfigurationPermissions, updateConfigurationPermissions } = useConfigurationsApi();
    const businessTeamsList = useSelector(getBusinessTeamsFullListFromState);
    const usersFullList = useSelector((state: RootState) => getUsersFullListFromState(state));
    const configuration = useSelector(getConfigurationsCurrentConfiguration);
    const { id } = useParams<{ id: string }>();
    const dispatch: AppDispatch = useDispatch();
    const classes = useStyles();

    const updatePermissions = useCallback(
        debounce(async (teamIds: number[], userIds: number[]) => {
            try {
                if (isDraft) {
                    await updateDraftConfigurationPermissions(configurationId || id, { teamIds, userIds });
                } else {
                    await updateConfigurationPermissions(configurationId || id, { teamIds, userIds });
                }
            } catch (error) {
                console.error('Failed to update configuration permissions', error);
            }
        }, 2000),
        [],
    );

    const getFilteredUserIds = (config, userList): string[] =>
        userList.items.filter((item) => config.users.includes(item.id)).map((item) => item.id);

    const getFilteredBusinessTeamIds = (config, businessTeamList): string[] =>
        businessTeamList.items.filter((item) => config.teams.includes(item.id)).map((item) => item.id);

    const handleBusinessTeamChange = (event: SelectChangeEvent<number[]>, newTeams: BusinessTeam[]): void => {
        const newTeamIds = newTeams.map((team) => team.id);
        dispatch(setCurrentConfigurationTeams(newTeamIds));
        updatePermissions(newTeamIds, getFilteredUserIds(configuration, usersFullList));
    };

    const handleUserChange = (event: SelectChangeEvent<number[]>, newUsers: User[]): void => {
        const newUserIds = newUsers.map((user) => user.id);
        dispatch(setCurrentConfigurationUsers(newUserIds));
        updatePermissions(getFilteredBusinessTeamIds(configuration, businessTeamsList), newUserIds);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <InputLabel>Allow access for a business team *:</InputLabel>
                <Autocomplete
                    multiple
                    classes={{
                        popper: classes.popper,
                        paper: classes.paper,
                        listbox: classes.listbox,
                        option: classes.option,
                    }}
                    options={businessTeamsList.items.filter((team: BusinessTeam) => team.isActive)}
                    getOptionLabel={(option: BusinessTeam) => option.name}
                    value={businessTeamsList.items.filter((team: BusinessTeam) =>
                        configuration.teams.includes(team.id),
                    )}
                    onChange={handleBusinessTeamChange}
                    renderInput={(params) => <CustomTextField {...params} />}
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel>Allow access for the individual users *:</InputLabel>
                <Autocomplete
                    multiple
                    classes={{
                        popper: classes.popper,
                        paper: classes.paper,
                        listbox: classes.listbox,
                        option: classes.option,
                    }}
                    options={usersFullList.items.filter((item: User) => item.isActive)}
                    getOptionLabel={(option: User) => `${option.firstName} ${option.lastName}`}
                    value={usersFullList.items.filter((item: User) => configuration.users.includes(item.id))}
                    onChange={handleUserChange}
                    renderInput={(params) => <CustomTextField {...params} />}
                />
            </Grid>
        </Grid>
    );
};

export default PermissionsTab;
