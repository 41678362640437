import React from 'react';
import { createRoot } from 'react-dom/client';
import AppLoader from './app-loader';

const container = document.querySelector('#app');

if (!container) {
    throw new Error('The root element for mounting the React app is not found!');
}

const root = createRoot(container);

root.render(<AppLoader />);
