import { useApiClient } from '@/api-client/okta-api-client-controller';
import { FileUploadResponse } from '@/types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFilesApi = () => {
    const { apiClient } = useApiClient();

    const getFile = async (uuid: string, extension: string): Promise<unknown> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<unknown>(`/api/v1/files/get/${uuid}.${extension}`, { method: 'GET' }, `getFile-${uuid}`);
    };

    const downloadFile = async (uuid: string, extension: string): Promise<unknown> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<unknown>(
            `/api/v1/files/download/${uuid}.${extension}`,
            { method: 'GET' },
            `downloadFile-${uuid}`,
        );
    };

    const uploadFile = async (
        file: File,
        type: 'avatar' | 'configuration' | 'template' | 'data',
    ): Promise<FileUploadResponse> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type);

        return apiClient.fetch<FileUploadResponse>(
            '/api/v1/files',
            {
                method: 'POST',
                body: formData,
            },
            'uploadFile',
        );
    };

    return {
        getFile,
        downloadFile,
        uploadFile,
    };
};
